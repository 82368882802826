<template>
    <div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'PaypalRedirect',
    data() {
      return {
      };
    },
    methods: {
    },
    computed: {
    },
    mounted() {
        if(this.$route.query.action != null && this.$route.query.action === "success"){
            window.close();
        } else {
            window.opener.location = '/autopay/cancel';
            window.close();
        }
    }
  };
  </script>
  
  <style scoped>
  </style>