<template>
  <div class="qbp-scheduled-to-pay">
    <QBPScheduledToPay/>
    <link rel="stylesheet" type="text/css" :href="this.hcdeTokenTheme" :integrity="this.hcdeTokenThemeHash" crossorigin="anonymous"/>
    <link rel="stylesheet" type="text/css" :href="this.hcdewalletTheme" :integrity="this.hcdewalletThemeHash" crossorigin="anonymous" />
  </div>
</template>

<script>   
  import QBPScheduledToPay from '../components/qbp/QBPScheduledToPay.vue'
  import links from '../js/links';
  import constants from '../js/constants';
  export default {
    name: 'Promise To Pay',
    components: {
      QBPScheduledToPay
    },
    data() {
      return {
        isBrimOn: false,
        hcdeTokenTheme: null,
        hcdewalletTheme: null,
        hcdeTokenThemeHash: null,
        hcdewalletThemeHash: null
      }
    },
    mounted() {
      this.isBrimOn = process.env.VUE_APP_BRIM;
      if(this.isBrimOn == 'true'){
        this.hcdeTokenTheme = links.hcdeTokenTheme;
        this.hcdewalletTheme = links.hcdewalletTheme;
        this.hcdeTokenThemeHash = constants.hcdeTokenThemeHash;
        this.hcdewalletThemeHash = constants.hcdewalletThemeHash;
      }
    }
  }
</script>

<style scoped>
  .qbp-scheduled-to-pay {
      font-family: Maison Neue,sans-serif;
      margin-top: -20px;
      padding-top: 20px;
      background: #FAFAFA;
  }
  #app .qbp-scheduled-to-pay {
      text-align: left;
  }
  .okBtn {
      padding-right: 20px;
      padding-bottom: 20px;
  }
  .okBtn a{
      color: #fff ;
      text-decoration: none ;
  }
  .okBtn a:hover{
      color: #fff;
      text-decoration: none;
  }
  .modalContent {
      padding-bottom: 0;
      padding-top: 35px;
  }
</style>