<template>
    <div class="container">
        <div class="row">
            <div v-if="this.autopaySuccess">
                <h1 data-test="autopaySuccess" class="text-decor text-start"><em class="bi bi-check-circle green-checkmark"></em>
                    You've set up AutoPay</h1>
                <div class="text-start">
                    <p>Your enrollment will take effect soon. Keep making payments until it is active.
                    </p>
                    <p>Once processing of your AutoPay set up is complete and active, monthly payments for full amount
                        will
                        be paid to CenturyLink.</p>
                    <p>We've emailed a confirmation of your AutoPay setup to <strong>{{ autopayEmail }}</strong></p>
                </div>
            </div>
            <div v-if="this.autopayRemove">
                <div class="autopay-success-box">
                    <div class="autopay-works-text">
                        <i class="bi bi-info-circle text-primary" style="font-size: 1.4rem;"></i>
                        <h1 class="data-placement autpay-text" data-test="autopayRemove">Your request to stop AutoPay has been submitted!</h1>
                    </div>
                    <p>We're in the process of removing AutoPay from your account. Once we finish the change,
                        your bill will no longer be paid automatically each month through:
                    </p>
                    <strong>{{ autopayMethodUsed }}</strong>
                    <p>We have sent an email confirming this request to: <span class="email-bold">{{ autopayEmail }}</span></p>
                    <p>We hope AutoPay freed you up to do other things with your time. And remember If you should
                        change
                        your mind,
                        you can always <a class="home-page" :href="`/autopay/manage?token=${token}`">sign up again</a> whenever you
                        like.
                    </p>
                    <div class="autopay-works-text">
                        <i class="bi bi-info-circle text-primary"></i>
                        <div class="text-start data-placement">Please note, any bill generated before we've finished
                            removing
                            AutoPay from your account will still be paid automatically. It typically takes 1 billing
                            cycle
                            for a payments through AutoPay to stop.
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="this.autopayUpdate">
                <h1 class="text-decor text-start"><em class="bi bi-check-circle green-checkmark"></em>
                    You've set up AutoPay</h1>
                <div class="text-start">It typically takes one billing
                    cycle for your new AutoPay information to take effect. Your monthly bill will
                    continue
                    to be paid
                    through your old AutoPay payment method as usual until the new information is
                    applied to
                    your account. If
                    you don't want your current payment made using your old AutoPay information, please
                    make
                    a payment manually
                    using your preferred payment method.
                </div>
            </div>
            <div v-if="enrollmentType !== null && enrollmentType !== ''">
                <div class="autopay-section">
                    <div class="bill-amount">
                        <h3 class="text-decor text-start">AutoPay Summary</h3>
                        <div v-if="this.prepaid || this.isPayPal">
                            <p class="text-start">Future monthly payments will be automatically deducted from your PayPal account. </p>
                        </div>
                        <div v-if="autopayMethodUsed !== null && autopayMethodUsed !== '' && !this.isPayPal">
                            <div class="row text-decor">
                                <div class="col-1"><span class='bi bi-cash-stack icon-circle'></span></div>
                                <div class="col-11">
                                    <div class="row">
                                        <div class="col-xs-12 text-start detail-margin">AutoPay Method</div>
                                        <div class="col-xs-12 text-start detail-margin">{{ autopayMethodUsed }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <a class="btn manage-autopay-button" :href="`/autopay/manage?token=${token}`">Manage AutoPay</a>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="technicalIsuue">
                <div class="marginbottom">
                    <h2>Our apologies, this page is experiencing technical issues.</h2>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Popover } from "bootstrap/dist/js/bootstrap.bundle";
import constants from '../../js/constants';
import { SET_DATA, GET_DATA } from '../../store/sessioncontants';
import { SET_DATA as local_setdata} from "../../store/storecontants";
import axios from "axios";
export default {
    name: 'SuccessAutopay',
    data() {
        return {
            autopaySuccess: false,
            autopayRemove: false,
            autopayUpdate: false,
            urlRouter: null,
            accountNumber: '',
            autopayMethodUsed: '',
            autopayEmail: '',
            enrollmentType: '',
            billAmount: '',
            technicalIsuue: '',
            token: null,
            prepaid: false,
            brimAutopayEnrollmentStatus: null,
            isPayPal: false
        }
    },
    mounted() {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'ap_success',
            flow: {
                name: 'autopay',
                step: 'success'
            },
        });
        Array.from(
            document.querySelectorAll('button[data-bs-toggle="popover"]')
        ).forEach((popoverNode) => new Popover(popoverNode));
        this.accountNumber = this.$store.getters[GET_DATA]("accountNumber") ? this.$store.getters[GET_DATA]("accountNumber") : "NA";
        this.token = this.$store.getters[GET_DATA]("token");
        this.isBrimOn = process.env.VUE_APP_BRIM;
        this.brimAutopayEnrollmentStatus = this.$store.getters[GET_DATA]('enrollmentType') ? 'DeEnroll' : null
        this.BrimEnroll = this.$store.getters[GET_DATA]('BrimEnroll') ? 'Enroll' : null
        this.brimAutopayModify = this.$store.getters[GET_DATA]('modifyenrollment') ? 'Modify' : null
        if(this.isBrimOn == 'true'){
            this.urlRouter =  this.$store.getters[GET_DATA]("walletRefNumberBrim");
            this.getAutopayCustomerDetails();
        }else {
            this.urlRouter = this.$route.query.walletRef;
            this.getAutopayEnrollmentDetails();
        }
    },
    methods: {
        async getAutopayEnrollmentDetails() {
            let localData = new Map();
            localData.set('showLoading', true);
            this.$store.dispatch(local_setdata, {attributes:localData});
            const params = {
                accountNumber: this.accountNumber,
                walletRefNumber: this.urlRouter,
            };
            try {
                const autopayEnrollmentResult = await axios.post(constants.AutopayEnrollmentUrl, params);
                this.enrollmentType = autopayEnrollmentResult.data.autoPayDetails.enrollmentType;
                if (this.enrollmentType === 'AutoPayEnrollment' || this.BrimEnroll == 'Enroll') { 
                    this.autopaySuccess = true;
                }
                else if (this.enrollmentType === 'AutoPayDeEnrollment' || this.brimAutopayEnrollmentStatus == 'DeEnroll') {
                     this.autopayRemove = true; 
                }
                else if (this.enrollmentType === 'AutoPayModifyEnrollment' || this.brimAutopayModify == 'Modify') {
                    this.autopayUpdate = true; 
                }
                else { this.technicalIsuue = true; }
                if(autopayEnrollmentResult.data.autoPayDetails.transactionAccount) {
                    if(autopayEnrollmentResult.data.autoPayDetails.transactionAccount.transactionType === 'bank') {
                        if(autopayEnrollmentResult.data.autoPayDetails.transactionAccount.bank.accountType !== null){
                            this.autopayMethodUsed = autopayEnrollmentResult.data.autoPayDetails.transactionAccount.bank.accountType + autopayEnrollmentResult.data.autoPayDetails.transactionAccount.bank.maskedPaymentAcctId
                        } else {
                            this.autopayMethodUsed = autopayEnrollmentResult.data.autoPayDetails.transactionAccount.bank.maskedPaymentAcctId

                        }
                    } else if(autopayEnrollmentResult.data.autoPayDetails.transactionAccount.transactionType === 'card') {
                        if(autopayEnrollmentResult.data.autoPayDetails.transactionAccount.card.cardBrand !== null){
                            this.autopayMethodUsed = autopayEnrollmentResult.data.autoPayDetails.transactionAccount.card.cardBrand + autopayEnrollmentResult.data.autoPayDetails.transactionAccount.card.maskedPaymentAcctId
                        } else {
                        this.autopayMethodUsed = autopayEnrollmentResult.data.autoPayDetails.transactionAccount.card.maskedPaymentAcctId
                        }
                    }
                } else {
                    if(this.accountNumber.includes('PPB')) {
                        this.prepaid = true
                    }else {
                        this.prepaid = false
                    }
                }
                this.autopayEmail = autopayEnrollmentResult.data.autoPayDetails.emailAddress;
                this.billAmount = this.twoDecimal(this.$store.getters[GET_DATA]('billAmount'));
                localData.set('showLoading', false);
                this.$store.dispatch(local_setdata, {attributes:localData});
                setTimeout(function () {
                    Array.from(
                        document.querySelectorAll('button[data-bs-toggle="popover"]')
                    ).forEach((popoverNode) => { new Popover(popoverNode); });
                }, 0);

            } catch (error) {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: 'ap_setup_error',
                    flow: {
                        name: 'setup autopay',
                        step: 'error'
                    },
                });
                console.log(error);
                localData.set('showLoading', false);
                this.$store.dispatch(local_setdata, {attributes:localData});
            }
            localData.set('showLoading', false);
            this.$store.dispatch(local_setdata, {attributes:localData});
        },
        twoDecimal(twodecimal) {
            let num = parseInt(twodecimal)
            let with2Decimals = num.toFixed(2);
            return with2Decimals;
        },
        async getAutopayCustomerDetails() {
            let localData = new Map();
            localData.set('showLoading', true);
            this.$store.dispatch(local_setdata, {attributes:localData});
            try {
                const params = { ban: this.accountNumber };
                const res = await axios.get(constants.BrimAutopayCustomerDetails, { params });
                if (this.BrimEnroll == 'Enroll') { 
                    this.autopaySuccess = true;
                    this.enrollmentType = 'Enroll';
                }
                else if (this.brimAutopayEnrollmentStatus == 'DeEnroll') {
                     this.autopayRemove = true; 
                     this.enrollmentType = 'DeEnroll';
                }
                else if (this.brimAutopayModify == 'Modify') {
                    this.autopayUpdate = true;
                    this.enrollmentType = 'Modify';
                }
                else { this.technicalIsuue = true; }
                if (res.data) {
                    let autopayMethodType = res.data.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].autopayEnrollmentInfo.autopayPaymentMethodCd;
                    let autopayMethodNumber = res.data.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].autopayEnrollmentInfo.maskedAccountNumber;
                    if (autopayMethodType == 'CARD') {
                        let cardType = res.data.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].autopayEnrollmentInfo.cardType;
                        this.autopayMethodUsed = cardType + ' ' + autopayMethodNumber;
                    } else if (autopayMethodType == 'ACH') {
                        let bankAccountType = res.data.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].autopayEnrollmentInfo.bankAccountType;
                        this.autopayMethodUsed = bankAccountType + ' ' + autopayMethodNumber;
                    } else if (autopayMethodType == 'PAYPAL') {
                        this.autopayMethodUsed = 'PayPal ' + autopayMethodNumber;
                    } else {
                        this.autopayMethodUsed = autopayMethodNumber;
                    }
                    this.autopayEmail = res.data.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.email ? res.data.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.email : this.$store.getters[GET_DATA]('emailAddress');
                    this.isPayPal = res.data.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].autopayEnrollmentInfo.autopayPaymentMethodCd==="PAYPAL" ? true : false;
                    localData.set('showLoading', false);
                    this.$store.dispatch(local_setdata, {attributes:localData});
                } else{
                    localData.set('showLoading', false);
                    this.$store.dispatch(local_setdata, {attributes:localData});
                }
            } catch (err) {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: 'ap_setup_error',
                    flow: {
                        name: 'setup autopay',
                        step: 'error'
                    },
                });
                console.log(err);
                localData.set('showLoading', false);
                this.$store.dispatch(local_setdata, {attributes:localData});
            }
        }
    }
}
</script>
<style>
.autpay-text {
    color: #14161a;
    font-weight: 500;
}

.link-decor {
    color: #0047bb;
    cursor: pointer;
    text-decoration: none;
}

.green-checkmark {
    font-size: 20px;
    color: #20d220;
}

.blue-info {
    width: 15px;
    height: 15px;
}

.text-decor {
    color: #14161a;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.align-div {
    display: inline-flex;
    margin-top: 1rem;
    margin-bottom: 1rem;
    align-items: center;
    height: auto;
    vertical-align: middle;
}

.autopay-works-text {
    display: inline-flex;
    margin-top: 1rem;
    margin-bottom: 1rem;
    height: auto;
}

.data-placement {
    margin-left: 0.5rem;
}

.autopay-success-box {
    margin-top: 1rem;
    margin-bottom: 1rem;
    align-items: center;
    height: auto;
    vertical-align: middle;
    text-align: left !important;
}

@media screen and (min-width: 375px) and (max-width: 979px) {
    .autopay-success-box {
        margin-top: 1rem;
        margin-bottom: 1rem;
        align-items: center;
        height: auto;
        vertical-align: middle;
    }
}

.autopay-section {
    width: 100%;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0px 2px 4px #00000033;
    border: 1px solid rgba(0, 0, 0, 0.125);
    margin: 2rem 0 2rem 0;
    padding-bottom: 20px;
}

.icon-circle {
    display: inline-block;
    border-radius: 60px;
    box-shadow: 0 0 2px #888;
    padding: 0.5em 0.6em;
    color: #0047bb;
    background-color: #e0e8f7;
}

.bill-amount {
    margin-left: 1rem;
}

@media screen and (min-width: 375px) and (max-width: 979px) {
    .detail-margin {
        margin-left: 1rem;
    }
}

.manage-autopay-button {
    background-color: #ffffff;
    box-shadow: 0px 2px 4px #00000033;
    border: 1px solid #0047bb;
    color: #0047bb;
    width: 12rem;
    margin: 1rem 0 0 1rem;
}

.manage-autopay-button:hover {
    background-color: rgba(0,71,187,0.039);
    color: #0047BB;
    border: 1px solid #0047BB;
}
.marginbottom {
    margin-bottom: 20px;
}
h1.text-decor {
    font-size: 36px;
}
h1.data-placement {
    font-size: 36px;
}
.email-bold {
    font-weight: bold;
}
</style>