<template>
  <div class="set-margin-auto justify-content-center bill-box">
    <post-login-bill-view/>
  </div>
  <link rel="stylesheet" type="text/css" :href="this.hcdeTokenTheme" :integrity="this.hcdeTokenThemeHash" crossorigin="anonymous"/>
  <link rel="stylesheet" type="text/css" :href="this.hcdewalletTheme" :integrity="this.hcdewalletThemeHash" crossorigin="anonymous" />
</template>

<script>
  import PostLoginBillView from '../components/Postbill/postBillView.vue'
  import links from '../js/links';
  import constants from '../js/constants';
  export default {
    name: 'PostBillView',
    components: {
      PostLoginBillView
    },
    data() {
      return {
        isBrimOn: false,
        hcdeTokenTheme: null,
        hcdewalletTheme: null,
        hcdeTokenThemeHash: null,
        hcdewalletThemeHash: null
      }
    },
    mounted() {
      this.isBrimOn = process.env.VUE_APP_BRIM;
      if(this.isBrimOn == 'true'){
        this.hcdeTokenTheme = links.hcdeTokenTheme;
        this.hcdewalletTheme = links.hcdewalletTheme;
        this.hcdeTokenThemeHash = constants.hcdeTokenThemeHash;
        this.hcdewalletThemeHash = constants.hcdewalletThemeHash;
      }
    }
  }
</script>

<style>
  .bill-box {
    width: 60%;
    height: 100%;
    border-radius: 5px;
  }
  .set-margin-auto {
    margin: auto;
  }
  @media screen and (min-width: 375px) and (max-width: 979px) {
    .bill-box {
      width: 100%;
      height: 100%;
      border-radius: 5px;
    }
  }
</style>