<template>
  <div v-if="this.iframe == null && this.errorMessage == 'QBP_BLOCKED_ACCOUNT'">
    <BlockedAccount />
  </div>
  <div class="qbp-payment-confirm">
    <div class="text-center" v-if="this.showPaNoti">
      <p class="pa-info"><img class="info-img" alt="info-image" src="../../images/epwfBlueInfoCirc.png"/><span class="span-text-pa text-start">You are enrolled in a Payment Plan</span><button type="button" id="pa-notify-close" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" @click="onCrossClick()"></button></p>
    </div>
    <!-- message for prepaid nonsuspended account start -->
    <div class="qbp-account-deatils" v-if="this.prepaidAccount == true && this.prepaidSuspendedAccount == false">
      <div class="col-12 col-md-12 col-xs-12 col-sm-12">
        <div id="qbp-intro-wrapper" class="marg-right">
          <div id="paymentError" class="col-md-12 col-sm-12 col-lg-12">
            <div class="outside outside2 outside-box">
              <div class="inside" id="dssp-intro-wrapper">
                <div class="prepaidAccountError">
                  <h3 class="prpd-qdd-err">This is a Prepaid account.</h3>
                  <p>Prepaid customers are billed automatically each month on a
                    credit or debit card of choice. Service is auto-renewed
                    until you choose to cancel.</p>
                  <p>
                    <a v-if="this.statusMessage == true" :href="'/qbp/multiAccount'" class="twoErrorZipSys" id="qbpSimplePay_signIn" style="text-decoration: none">Change Account</a>
                    <a v-else :href="MyaEntryPointURL" class="twoErrorZipSys" id="qbpSimplePay_signIn" style="text-decoration: none">Sign into My CenturyLink</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- message for prepaid nonsuspended account end -->
    <div class="col-md-12 col-xs-12" v-else>
      <!-- always display for postpaid and prepaid suspended start-->
      <div>
        <div id="dssp-intro-wrapper">
          <h1 data-test="messageData">Quick Bill Pay</h1>
          <h2>Payment for {{this.accountNumber}} </h2>
        </div>
        <div class="container-fluid total-amount-banner">
            <div class="qbp-confirm-details">
              <div v-if="this.balance <= -200.00 || this.errorMessage == 'QBP_AUTOPAY'" ></div> 
              <div class="row qbp-details" v-else>         
              <div class="col-6 col-sm-5 qbp-left-details">
                <div class="total-amt" v-if="balance">TOTAL AMOUNT DUE</div>
                <div class="total-bal" v-if="balance"><span class="dollar">$</span>{{ Number(this.balance).toFixed(2) }}</div>
                <div class="due-on" v-if="paymentDate">Due on {{ this.paymentDate }}</div>
                <div v-if="this.statusMessage == true">
                  <a :href="'/qbp/multiAccount'">Change Account </a>
                </div>
              </div>
              <div v-if="balance" class="col-6 col-sm-7 qbp-banner-img">
                <img alt="Banner-image" src="../../images/AmtBanner.png" />
              </div>
              </div>
            </div>
        </div>
        <div class="confirm-iframe-error">
          <!-- display iframe for payment -->
          <div
            v-if="this.iframe != null && this.errorMessage == null && (this.brim === false)"
          >
            <div class="confirm-heading">
              <div class="confirm-payment">PAYMENT METHOD</div>
              <div class="confirm-method">Choose your method of payment</div>
              <div class="confirm-account">You can pay using your bank account or credit/debit card.</div>
            </div>
            <div class="iframe-prepaid-border">
              <div v-if="this.prepaidSuspendedAccount == true">
                <div id="future-susp-pymt" style="padding: 0">
                  <div id="susppmtArr">
                    All future monthly payments for the full amount of your subscription
                    will be paid to CenturyLink automatically deducting from the payment
                    information you provide below.
                  </div>
                </div>
              </div>
              <iframe
                width="100%"
                style="height: 850px; border: none; overflow: hidden;"
                :style="{height: qbpIframeHeight + 'px'}"
                :onload="this.onQbpIframeChange"
                :src="this.iframe" title="Quick bill payment" class="qbp-iframe-container"
              ></iframe>
            </div>
          </div>
          <!-- diffrent errormessage if iframe is not getting due several condition start -->
          <div
            v-if="this.iframe != null && this.errorMessage == null && (this.brim === true)"
          >
            <div class="confirm-heading">
              <div class="confirm-payment">PAYMENT METHOD</div>
              <div class="confirm-method">Choose your method of payment</div>
              <div class="confirm-account">You can pay using your bank account or credit/debit card.</div>
            </div>
            <div class="card-bank-payment">
              <div v-if="this.prepaidSuspendedAccount == true">
                <div id="future-susp-pymt" style="padding: 0">
                  <div id="susppmtArr">
                    All future monthly payments for the full amount of your subscription
                    will be paid to CenturyLink automatically deducting from the payment
                    information you provide below.
                  </div>
                </div>
              </div>
              <button v-if="this.isAchAllowed" type="button" class="btn qbp-payment-cta" @click="bankOption">Pay With Bank Account</button>
              <button v-if="this.isCardAllowed" type="button" class="btn qbp-payment-cta" @click="cardOption">Pay With Credit or Debit Card</button>
            </div>
          </div>
          <div
            v-else-if="
              this.iframe == null && this.errorMessage == 'QBP_NO_BALANCE'
            "
          >
            <p>
              <NoBalance />
            </p>
          </div>
          <div
            v-else-if="this.iframe == null && this.errorMessage == 'QBP_AUTOPAY'"
          >
            <p>
              <AutoPayQbp />
            </p>
          </div>
          <div
            v-else-if="
              this.iframe == null && this.errorMessage == 'QBP_SERVICE_ERROR'
            "
          >
            <p>
              <ServiceError />
            </p>
          </div>
          <div
            v-else-if="
              this.iframe == null &&
              this.errorMessage == 'QBP_AMOUNT_DUE_UNAVAILABLE'
            "
          >
            <p>
              <NameOrAmtError />
            </p>
          </div>
          <div
            v-else-if="
              this.iframe == null &&
              this.errorMessage == 'QBP_BILLING_NAME_UNAVAILABLE'
            "
          >
            <p>
              <NameOrAmtError />
            </p>
          </div>
          <div
            v-else-if="
              this.iframe == null &&
              this.errorMessage == 'QBP_ACCOUNT_STATUS_EXCEPTION'
            "
          >
            <p>
              <NameOrAmtError />
            </p>
          </div>
          <div
            v-else-if="
              this.iframe == null &&
              this.errorMessage == 'QBP_SIMPLE_PAY_ACCOUNTS'
            "
          >
            <p>
              <SimplePAyAccts />
            </p>
          </div>
          <div
            v-else-if="
              this.iframe == null &&
              this.errorMessage == 'QBP_PREPAID_NON_SUSPENDED_ACCOUNT'
            "
          >
            <p>
              <Prepaid />
            </p>
          </div>
          <div v-else-if="showIframeError()">
            <p><IframeError /></p>
          </div>
          <!-- diffrent errormessage if iframe is not getting due several condition end -->
        </div>
      </div>
      <!-- always display for postpaid and prepaid suspended end -->
    </div>
  </div>
  
  <div id="myModal" class="modal fade" role="dialog">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header no-border">
          <h5 v-if="this.bankOptionTitle" class="modal-title">Enter bank account info</h5>
          <h5 v-if="this.cardOptionTitle" class="modal-title">Enter card info</h5>
					<button type="button" class="btn" @click="ModalClose()" aria-label="Close"> 
              <img src="../../images/svg/icon-navigation-close_24px.svg" alt="outline-info"/>
          </button>
				</div>
        <div class="bank-img-section" v-if="this.cardImg">
          <img class="visa-img" src="../../images/visa.svg" alt="visa">
          <img class="mastercard-img" src="../../images/mastercard.svg" alt="mastercard">
          <img class="discover-img" src="../../images/discover.svg" alt="discover">
        </div>
        <div class="modal-body">
          <div class="hcde-comp">
            <hcde-tokenization v-if="this.hcdeReady" :env="this.env" clientId="MYA_PAY" :sessionId="this.sessionId" callbackFunction="showCardPopup" callbackField="myTokenVar" statusFunction="tokenStatus" statusField="statusVar" heartBeatFrequency="60" srcSysTransId="307902021108">Loading...</hcde-tokenization>
            <hcde-wallet v-if="this.hcdeReadyWallet" :env="this.env" clientId="MYA_PAY" :sessionId="this.sessionId" callbackFunction="showBankPopup" callbackRefNumField="walletRefNum"  callbackInstrumentField="walletInstrumentId" statusFunction="walletStatus" statusField="statusVar" heartBeatFrequency="60" srcSysTransId="1234567890" >Loading...</hcde-wallet>
          </div>
        </div>
			</div>
		</div>
	</div>
  <div id="confirmationModal" class="modal large" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content content-size">
        <div class="modal-header no-border">
          <p class="modal-title-1">I (we) hereby authorize a payment debit entry to my (our) Credit/Debit/Share Draft account.
            I (we) understand that if the payment under this authorization is returned or otherwise dishonored,
            I (we) will promptly remit the payment due plus any fees due under my (our) account.</p>
        </div>
        <div class="modal-body">
          <div class="hcde-comp">
            <p>Please verify the information below. If anything looks incorrect, please: <button type="button" class="cancel-button" @click="ClosePopup()">Make Changes</button>.</p>
          </div>
          <div class="align-center">
            <div class="flex-component">
              <div class="flex-entry">
                <p class="flex-label">Bill Payment:</p><p class="flex-span"> USD ${{ Number(this.paymentAmountEntered).toFixed(2) }}</p>
              </div>
              <div v-if="this.selectedOption === 'Card'" class="flex-entry">
                <p class="flex-label">Convenience Fee:</p><p class="flex-span"> USD ${{ Number(this.convenienceFeeAmtCard).toFixed(2) }}</p>
              </div>
              <div v-if="this.selectedOption === 'Card'" class="flex-entry">
                <p class="flex-label">Total Payment:</p><p class="flex-span"> USD ${{ Number(parseFloat(this.paymentAmountEntered)+parseFloat(this.convenienceFeeAmtCard)).toFixed(2) }}</p>
              </div>
              <div v-if="this.selectedOption === 'Card'" class="flex-entry">
                <p class="flex-label">Payment Date:</p><p class="flex-span"> {{ this.currentPaymentDate() }}</p>
              </div>
              <div v-if="this.selectedOption === 'Card'" class="flex-entry">
                <p class="flex-label">Payment Method:</p><p class="flex-span"> {{ this.selectedOption }} {{ this.enteredCardNumber}}</p>
              </div>

              <div v-if="this.selectedOption === 'Bank'" class="flex-entry">
                <p class="flex-label">Convenience Fee:</p><p class="flex-span"> USD ${{ Number(this.convenienceFeeAmtAch).toFixed(2) }}</p>
              </div>
              <div v-if="this.selectedOption === 'Bank'" class="flex-entry">
                <p class="flex-label">Total Payment:</p><p class="flex-span"> USD ${{ Number(parseFloat(this.paymentAmountEntered)+parseFloat(this.convenienceFeeAmtAch)).toFixed(2) }}</p>
              </div>
              <div v-if="this.selectedOption === 'Bank'" class="flex-entry">
                <p class="flex-label">Payment Date:</p><p class="flex-span"> {{ this.currentPaymentDate() }}</p>
              </div>
              <div v-if="this.selectedOption === 'Bank'" class="flex-entry">
                <p class="flex-label">Payment Method:</p><p class="flex-span"> {{ this.selectedOption }} {{ this.enteredAccountNumber}}</p>
              </div>
            </div>
          </div>
          <div v-if="this.selectedOption === 'Card'" class="hcde-comp">
            <p class="alert-content">By clicking "Submit Payment", you are authorizing your financial institution to charge your account USD  ${{ Number(parseFloat(this.paymentAmountEntered)+parseFloat(this.convenienceFeeAmtCard)).toFixed(2) }}.
              This included a ${{ Number(this.convenienceFeeAmtCard).toFixed(2) }} convenience fee for using this service.</p>
          </div>
          <div v-if="this.selectedOption === 'Bank'" class="hcde-comp">
            <p class="alert-content">*Convenience fee discount for using cash or cash equivalent payment method.</p>
            <p class="alert-content">By clicking "Submit Payment", you are authorizing your financial institution to charge your account USD  ${{ Number(parseFloat(this.paymentAmountEntered)+parseFloat(this.convenienceFeeAmtAch)).toFixed(2) }}.</p>
          </div>
          <div class="flex-component-button content-spacing">
            <div class="flex-entry-button">
              <button class="Submit-Button" @click="ClosePopup()">Cancel</button>
            </div>
            <div class="flex-entry-button">
              <div v-if="this.selectedOption === 'Card'" class="flex-entry">
                <button class="Submit-Button" @click="createCardPaymentPlan(this.myTokenVar)">Submit</button>
              </div>
              <div v-if="this.selectedOption === 'Bank'" class="flex-entry">
                <button class="Submit-Button" @click="createBankPaymentPlan(this.walletInstrumentId)">Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template> 

<script>
import { Modal } from "bootstrap/dist/js/bootstrap.bundle";
import { GET_DATA, SET_DATA } from "../../store/storecontants";
import axios from 'axios';
import constants from '../../js/constants';
import AutoPayQbp from "../ErrorMessages/autoPayQbp";
import SimplePAyAccts from "../ErrorMessages/simplePayAccts";
import NoBalance from "../ErrorMessages/noBalance";
import NameOrAmtError from "../ErrorMessages/NameOrAmtError";
import IframeError from "../ErrorMessages/IframeError";
import Prepaid from "../ErrorMessages/Prepaid";
import ServiceError from "../ErrorMessages/ServiceError";
import BlockedAccount from "../ErrorMessages/BlockedAccount";
import links from '../../js/links';
import { pii } from "../../js/pii";
import hcdeStatus from "../../js/hcdestatus";

export default {
  components: {
    NoBalance,
    AutoPayQbp,
    SimplePAyAccts,
    NameOrAmtError,
    IframeError,
    Prepaid,
    ServiceError,
    BlockedAccount
  },
  data() {
    return {
      iframe: null,
      accountNumber: null,
      balance: null,
      errorMessage: null,
      statusMessage: null,
      prepaidSuspendedAccount: null,
      paymentDate: null,
      prepaidAccount: null,
      MyaEntryPointURL: links.MyaEntryPointURL,
      showPaNoti: false,
      qbpIframeChange: 0,
      qbpIframeHeight: 850,
      hcdeReady: false,
      hcdeReadyWallet: false,
      myTokenVar: null,
      walletInstrumentId: null,
      hcdeTagString: null,
      brim: false,
      WalletJSEncrypt: null,
      hcdeWalletServiceApi: null,
      hcdeTokenServiceApi: null,
      hcdeWalletComponent: null,
      hcdeTokenComponent: null,
      paymentModal: null,
      sessionId: null,
      formattedDate: '',
      bankOptionTitle: false,
      cardOptionTitle: false,
      cardImg: false,
      paymentProcessedDate: null,
      firstName: null,
      lastName: null,
      emailAddress: null,
      ban: null,
      isCardAllowed: false,
      isAchAllowed: false,
      convenienceFeeAmtAch: null,
      convenienceFeeAmtCard: null,
      selectedOption: null,
      paymentAmountEntered: null,
      enteredAccountNumber: null,
      enteredCardNumber: null
    };
  },
  updated() {
    this.isBrimOn = process.env.VUE_APP_BRIM;
    if(this.isBrimOn == 'true'){
    this.createHcdeTagListener()  
    }  
  },
  methods: {
    formatPaymentProcessedDate(date){
      const day = date.toLocaleString("en-us", {
        day: "2-digit",
      });
      const month = date.toLocaleString("en-us", {
        month: "2-digit",
      });
      const year = date.getFullYear();
      const formattedDate = year + '-' + month + '-' + day;
      return formattedDate;
    },
    checkStatus(status) {
      if(status.includes("EXPIRED")){
          this.ModalClose()
          this.$router.push({
              name: "FailureOfPayment",
              params: {showTimeout: 'timeOut'},
          });
      }
    },
    async createCardPaymentPlan(token){
      this.ClosePopup();
      let attributesData = new Map();
      attributesData.set('showLoading', true);
      this.$store.dispatch(SET_DATA, { attributes: attributesData });
      let paymentAmount = null;
      let inputtedPaymentAmount = document.getElementById('PaymentCrAmount');
      if (Math.sign(inputtedPaymentAmount.value) < 0) {
        paymentAmount = '5'
      } else {
        paymentAmount = inputtedPaymentAmount.value
      }
      try {
        let params = {
          accountNumber: this.ban,
          accountType: 'CON',
          firstName: this.firstName,
          lastName: this.lastName,
          businessName: null,
          emailId: this.emailAddress,
          paymentAmount: paymentAmount.replace('$', ''),
          paymentProcessedDate: this.paymentProcessedDate,
          cardToken: token,
          bankToken: null,
          walletReferenceNumber: null,
          instrumentId: null,
          paymentMethodCd: 'CARD',
        }
        const res = await axios.post(constants.BrimQuickBillPayCreate, params);
        if (res.data.transactionStatus === 'S') {
            let attributesData = new Map();
            attributesData.set('confirmationNumber', res.data.masterPaymentId);
            attributesData.set('showLoading', false);
            this.$store.dispatch(SET_DATA, {attributes:attributesData});
            this.ModalClose();
            this.$router.push({
              name: "SuccessfulPayments",
              params: {},
            });
        }  else {
            let attributesData = new Map();
            attributesData.set('errorCd', res.data.errorInfo.errorCd);
            attributesData.set('showLoading', false);
            this.$store.dispatch(SET_DATA, {attributes:attributesData});
            this.ModalClose();
            this.$router.push({
              name: "FailureOfPayment",
              params: {},
            });
        }
      } catch (error) {
        console.log(error);
        let attributesData = new Map();
        attributesData.set('showLoading', false);
        this.$store.dispatch(SET_DATA, { attributes: attributesData });
        this.ModalClose();
        this.$router.push({
          name: "FailureOfPayment",
          params: {},
        });
      }
    },
    async createBankPaymentPlan(token){
      let paymentAmount = null;
      this.ClosePopup();
      let attributesData = new Map();
      attributesData.set('showLoading', true);
      this.$store.dispatch(SET_DATA, { attributes: attributesData });
      let inputtedPaymentAmount = document.getElementById('PaymentAmount');
      paymentAmount = inputtedPaymentAmount.value
      try {
        let params = {
          accountNumber: this.ban,
          accountType: 'CON',
          firstName: this.firstName,
          lastName: this.lastName,
          businessName: null,
          emailId: this.emailAddress,
          paymentAmount: paymentAmount.replace('$', ''),
          paymentProcessedDate: this.paymentProcessedDate,
          cardToken: null,
          bankToken: token,
          walletReferenceNumber: null,
          instrumentId: null,
          paymentMethodCd: 'ACH',
        }
        const res = await axios.post(constants.BrimQuickBillPayCreate, params);
        if (res.data.transactionStatus === 'S') {
          let attributesData = new Map();
          attributesData.set('confirmationNumber', res.data.masterPaymentId);
          attributesData.set('showLoading', false);
          this.$store.dispatch(SET_DATA, {attributes:attributesData});
          this.ModalClose();
          this.$router.push({
            name: "SuccessfulPayments",
            params: {},
          });
        }  else {
          let attributesData = new Map();
          attributesData.set('errorCd', res.data.errorInfo.errorCd);
          attributesData.set('showLoading', false);
          this.$store.dispatch(SET_DATA, {attributes:attributesData});
          this.ModalClose();
          this.$router.push({
            name: "FailureOfPayment",
            params: {},
          });
        }
      } catch (error) {
        console.log(error);
        let attributesData = new Map();
        attributesData.set('showLoading', false);
        this.$store.dispatch(SET_DATA, { attributes: attributesData });
        this.ModalClose();
        this.$router.push({
          name: "FailureOfPayment",
          params: {},
        });
      }
    },
    async createBankPopupModal(token) {
      this.walletInstrumentId = token;
      let paymentAmount = null;
      let inputtedPaymentAmount = document.getElementById('PaymentAmount');
      paymentAmount = inputtedPaymentAmount.value
      this.paymentAmountEntered = paymentAmount.replace('$', '');
      let bankAccountNum = document.getElementById('bankAccountNumber');
      this.enteredAccountNumber = bankAccountNum.value;
      this.popupModal.show();
      this.selectedOption = 'Bank'
    },
    async createCardPopupModal(token) {
      this.myTokenVar = token;
      let paymentAmount = null;
      let inputtedPaymentAmount = document.getElementById('PaymentCrAmount');
      paymentAmount = inputtedPaymentAmount.value
      let CardNumber = document.getElementById('hcdeCardNumber');
      this.enteredCardNumber = CardNumber.value;
      this.paymentAmountEntered = paymentAmount.replace('$', '');
      this.popupModal.show();
      this.selectedOption = 'Card'
    },
    onQbpIframeChange(){
      if( this.qbpIframeChange == 1 ){
        this.qbpIframeHeight = 1425;
      }else{
        this.qbpIframeChange++;
      }
    },
    showIframeError() {
      if(this.iframe === null && this.errorMessage == null) {
        return true;
      } else {
        return false;
      }
    },
    // date readable formate
    getHumanReadbledt(timeStamp) {
      if (timeStamp == null) {
        return null;
      }
      let attributesData = new Map();
      attributesData.set('showLoading', true);
      this.$store.dispatch(SET_DATA, {attributes:attributesData});
      const monthArr = [
        "January",

        "February",

        "March",

        "April",

        "May",

        "June",

        "July",

        "August",

        "September",

        "October",

        "November",

        "December",
      ];
      const date = new Date(timeStamp);
      const year = date.getFullYear();
      const month = monthArr[date.getMonth()];
      const newDate = date.getDate();
      const readableDate = month + " " + newDate + ", " + year;
      attributesData.set('showLoading', false);
      this.$store.dispatch(SET_DATA, {attributes:attributesData});
      return readableDate;
    },
    onCrossClick () {
      this.showPaNoti = false;
    },
    removeJSfile(oldfilename) {
            let targetelement = 'script';
            let targetattr = 'src';
            let allsuspects = document.getElementsByTagName(targetelement);
            for (let i = allsuspects.length; i >= 0; i--) {
                //search backwards within nodelist for matching elements to remove
                if (allsuspects[i] && allsuspects[i].getAttribute(targetattr) != null && allsuspects[i].getAttribute(targetattr).indexOf(oldfilename) != -1) {
                    allsuspects[i].parentNode.removeChild(allsuspects[i]);
                }
            }
    },
    async createHcdeTagListener() {
        const scripts = [
            this.WalletJSEncrypt,
            this.hcdeTokenServiceApi,
            this.hcdeWalletServiceApi,
            this.hcdePaypalServiceApi
        ];
        const integrity = {
            [this.WalletJSEncrypt]: constants.jsencryptHash,
            [this.hcdeTokenServiceApi]: constants.hcdeTokenServiceApiHash,
            [this.hcdeWalletServiceApi]: constants.hcdeWalletServiceApiHash,
            [this.hcdePaypalServiceApi]: constants.hcdePaypalServiceApiHash
        };
        scripts.forEach(script => {
            let tag = document.head.querySelector(`[src="${ script }"`);
            if (!tag) {
                tag = document.createElement("script");
                tag.setAttribute("src", script);
                tag.setAttribute("type", 'text/javascript');
                tag.setAttribute("integrity", integrity[script]);
                tag.setAttribute("crossorigin",'anonymous');
                document.head.appendChild(tag); 
            }
        });
    },
    cardOption() {
      this.paymentModal.show();
      this.bankOptionTitle = false;
      this.cardOptionTitle = true;
      this.cardImg = true;
      this.hcdeReady = true;
      this.hcdeReadyWallet = false;  
      const cardscripts = [
          this.hcdeTokenComponent				
      ];
      let formattedDateValue = this.formattedDate
      let bal = ''
      // let bal = this.balance;
      if (Math.sign(this.balance) < 0) {
        bal = '5.00'
      } else {
        bal = this.balance
      }
      cardscripts.forEach(script=>{
          let scriptFound = document.head.querySelector(`[src="${ script }"`) ? true : false;
          if (scriptFound) {
              this.removeJSfile(script);
          }
          let tag = document.head.querySelector(`[src="${ script }"`);
          if (!tag) {
              tag = document.createElement("script");
              tag.setAttribute("src", script);
              tag.setAttribute("type", 'text/javascript');
              tag.setAttribute("integrity", constants.hcdeTokenComponentHash);
              tag.setAttribute("crossorigin",'anonymous');
              document.head.appendChild(tag);
              tag.addEventListener('load', function () {
                  // eslint-disable-next-line no-undef
                  this.hcdeTagString = new HCDETokenServiceComponent();                  
                  const digitsOnlyRegExp = /^[0-9]*$/;

                  let hcdeCardNumCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(1)');
                  hcdeCardNumCon.setAttribute('id', 'hcdeCardNumberContainer');
                  hcdeCardNumCon.setAttribute('class', 'hcdeComponentInputContainer form-floating form-group col-12');

                  let hcdeCardNumLabel = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(1) > label');
                  hcdeCardNumLabel.innerText = "";

                  let hcdeCardNumInput = document.getElementById('hcdeCardNumber');
                  hcdeCardNumInput.setAttribute('class', 'hcdeComponentTextBox form-control');
                  hcdeCardNumInput.setAttribute('placeholder', ' ');
                  hcdeCardNumInput.setAttribute('maxlength', '16');
                  hcdeCardNumInput.setAttribute('oninput', 'onInputCardNum()');
                  hcdeCardNumInput.addEventListener("keypress", e => {
                      if (!digitsOnlyRegExp.test(e.key)) {
                          e.preventDefault();
                      }
                  });

                  let newCardNumLabel = document.createElement('label');
                  newCardNumLabel.setAttribute('class', 'hcdeComponentLabel d-inline-block text-start text-truncate form-label col-12');
                  newCardNumLabel.setAttribute('id', 'hcdeCardNumberLabel');
                  newCardNumLabel.innerText = "Card Number";
                  hcdeCardNumCon.appendChild(newCardNumLabel);
                  
                  let hcdeExpMonthCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(2)');
                  hcdeExpMonthCon.setAttribute('id', 'hcdeExpMonthContainer');
                  hcdeExpMonthCon.setAttribute('class', 'hcdeComponentInputContainer col-6 pe-1');

                  let hcdeExpMonthLabel = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(2) > label');
                  hcdeExpMonthLabel.innerText = "";

                  let hcdeExpMonthInput = document.getElementById('hcdeCardExpiryMonth');
                  hcdeExpMonthInput.options[0].innerText = 'Expiration Month';
                  hcdeExpMonthInput.setAttribute('onchange', 'onInputExpMth()');

                  let hcdeExpYearCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(3)');
                  hcdeExpYearCon.setAttribute('id', 'hcdeExpYearContainer');
                  hcdeExpYearCon.setAttribute('class', 'hcdeComponentInputContainer col-6 ps-1');

                  let hcdeExpYearLabel = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(3) > label');
                  hcdeExpYearLabel.innerText = "";
                  
                  let hcdeExpYearInput = document.getElementById('hcdeCardExpiryYear');
                  hcdeExpYearInput.options[0].innerText = 'Expiration Year';
                  hcdeExpYearInput.setAttribute('onchange', 'onInputExpYr()');

                  let hcdeZipCodeCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(4)');
                  hcdeZipCodeCon.setAttribute('id', 'hcdeZipCodeContainer');
                  hcdeZipCodeCon.setAttribute('class', 'hcdeComponentInputContainer form-floating form-group col-6 pe-1');

                  let hcdeZipCodeLabel = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(4) > label');
                  hcdeZipCodeLabel.innerText = "";

                  let hcdeZipCodeInput = document.getElementById('hcdeZipCode');
                  hcdeZipCodeInput.setAttribute('class', 'hcdeComponentTextBox form-control');
                  hcdeZipCodeInput.setAttribute('placeholder', ' ');
                  hcdeZipCodeInput.setAttribute('maxlength', '5');
                  hcdeZipCodeInput.setAttribute('oninput', 'onInputZipCode()');
                  hcdeZipCodeInput.addEventListener("keypress", e => {
                      if (!digitsOnlyRegExp.test(e.key)) {
                          e.preventDefault();
                      }
                  });

                  let newZipCodeLabel = document.createElement('label');
                  newZipCodeLabel.setAttribute('class', 'hcdeComponentLabel d-inline-block text-start text-truncate form-label col-12');
                  newZipCodeLabel.setAttribute('id', 'hcdeZipCodeLabel');
                  newZipCodeLabel.innerText = "Zip Code";
                  hcdeZipCodeCon.appendChild(newZipCodeLabel);

                  let hcdeCrAmount = document.createElement("div");
                  hcdeCrAmount.setAttribute('class', 'hcdeComponentInputContainer form-floating form-group col-12')
                  hcdeCrAmount.setAttribute('id', 'hcdeCrAmount');

                  let hcdeCrAmountLab = document.createElement("label");
                  hcdeCrAmountLab.setAttribute('for', 'PaymentCrAmount')
                  hcdeCrAmountLab.setAttribute('class', 'hcdeComponentLabel')

                  let hcdeCrAmountInput = document.createElement("input");
                  hcdeCrAmountInput.setAttribute('type', 'text')
                  hcdeCrAmountInput.setAttribute('name', 'paymentCrAmt')
                  hcdeCrAmountInput.setAttribute('class', 'hcdeComponentTextBox form-control')
                  hcdeCrAmountInput.setAttribute('id', 'PaymentCrAmount')
                  hcdeCrAmountInput.setAttribute('maxlength', '10')
                  hcdeCrAmountInput.setAttribute('autocomplete', 'off')
                  hcdeCrAmountInput.setAttribute('placeholder', '')
                  hcdeCrAmountInput.setAttribute('oninput',`onCrAmount('${bal}')`);
                  hcdeCrAmountInput.setAttribute('onchange',`setMinValCr('${bal}')`);
                  hcdeCrAmountInput.value = '$' + bal;

                  let hcdeCrAmountLabel = document.createElement("label");
                  hcdeCrAmountLabel.setAttribute('class', 'hcdeComponentLabel d-inline-block text-start text-truncate form-label col-12')
                  hcdeCrAmountLabel.setAttribute('id', 'PaymentAmountLbl')
                  hcdeCrAmountLabel.innerText = "Payment Amount (Minimum $5.00)";
                  hcdeCrAmount.appendChild(hcdeCrAmountLab)
                  hcdeCrAmount.appendChild(hcdeCrAmountInput)
                  hcdeCrAmount.appendChild(hcdeCrAmountLabel)
                  let hcdeCrBank = document.querySelector('#hcdeZipCodeContainer')
                  hcdeCrBank.after(hcdeCrAmount)

                  let hcdeCrdDateAmount = document.createElement("div");
                  hcdeCrdDateAmount.setAttribute('class', 'hcdeComponentInputContainer form-floating form-group col-12')
                  hcdeCrdDateAmount.setAttribute('id', 'hcdeCrDateAmt');

                  let hcdeCrDateAmountLab = document.createElement("label");
                  hcdeCrDateAmountLab.setAttribute('for', 'PaymentCrDateTime')
                  hcdeCrDateAmountLab.setAttribute('class', 'hcdeComponentLabel')

                  let hcdeCrDateTimeInput = document.createElement("input");
                  hcdeCrDateTimeInput.setAttribute('type', 'text')
                  hcdeCrDateTimeInput.setAttribute('name', 'paymentCrDate')
                  hcdeCrDateTimeInput.setAttribute('class', 'hcdeComponentTextBox form-control')
                  hcdeCrDateTimeInput.setAttribute('id', 'PaymentCrDateTime')
                  hcdeCrDateTimeInput.setAttribute('maxlength', '10')
                  hcdeCrDateTimeInput.setAttribute('autocomplete', 'off')
                  hcdeCrDateTimeInput.setAttribute('placeholder', '')
                  hcdeCrDateTimeInput.setAttribute('disabled', 'true')

                  let hcdeCrDateTimeLabel = document.createElement("label");
                  hcdeCrDateTimeLabel.setAttribute('class', 'hcdeComponentLabel d-inline-block text-start text-truncate form-label col-12')
                  hcdeCrDateTimeLabel.setAttribute('id', 'PaymentCrAmountLbl')
                  hcdeCrDateTimeLabel.innerText = formattedDateValue

                  hcdeCrdDateAmount.appendChild(hcdeCrDateAmountLab)
                  hcdeCrdDateAmount.appendChild(hcdeCrDateTimeInput)
                  hcdeCrdDateAmount.appendChild(hcdeCrDateTimeLabel)
                  let hcdeCrDt = document.querySelector('#hcdeCrAmount')
                  hcdeCrDt.after(hcdeCrdDateAmount)

                  let hcdeButtonCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentButtonContainer');
                  hcdeButtonCon.setAttribute('id', 'hcdeSaveButtonContainer');

                  let hcdeSaveButton = document.getElementById('hcdeComponentSubmitButton');
                  hcdeSaveButton.setAttribute('value', 'Save');

                  // eslint-disable-next-line no-undef
                  checkCardInfo();
                  
              });
          }
      });
    },
    bankOption() {
        this.paymentModal.show();
        this.bankOptionTitle = true;
        this.cardOptionTitle = false;
        this.cardImg = false;
        this.hcdeReady = false;
        this.hcdeReadyWallet = true;
        const bankscripts = [
            this.hcdeWalletComponent				
        ];
        let formattedDateValue = this.formattedDate
        let bal = '';
        if (Math.sign(this.balance) < 0) {
          bal = '5.00'
        } else {
          bal = this.balance
        }
        bankscripts.forEach(script => {
            let scriptFound = document.head.querySelector(`[src="${ script }"`) ? true : false;
            if (scriptFound) {
                this.removeJSfile(script);
            }
            let tag = document.head.querySelector(`[src="${ script }"`);
            if (!tag) {
                tag = document.createElement("script");
                tag.setAttribute("src", script);
                tag.setAttribute("type", 'text/javascript');
                tag.setAttribute("integrity", constants.hcdeWalletComponentHash);
                tag.setAttribute("crossorigin",'anonymous');
                document.head.appendChild(tag); 
                tag.addEventListener('load', function () {
                  
                    // eslint-disable-next-line no-undef
                    this.hcdeTagString = new HCDEWalletServiceComponent();
                    const digitsOnlyRegExp = /^[0-9]*$/;
                    let hcdeBankDetailCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(1)');
                    hcdeBankDetailCon.setAttribute('id', 'hcdeBankDetailContainer');
                    hcdeBankDetailCon.setAttribute('class', 'hcdeComponentInputContainer form-floating form-group col-12');

                    let hcdeRouteNumLabel = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(1) > label');
                    hcdeRouteNumLabel.innerText = "";

                    let hcdeRouteNumInput = document.getElementById('bankRoutingNumber');
                    hcdeRouteNumInput.setAttribute('class', 'hcdeComponentTextBox form-control');
                    hcdeRouteNumInput.setAttribute('placeholder', ' ');
                    hcdeRouteNumInput.setAttribute('maxlength', '9');
                    hcdeRouteNumInput.setAttribute('oninput', 'onInputRouteNum()');
                    hcdeRouteNumInput.addEventListener("keypress", e => {
                        if (!digitsOnlyRegExp.test(e.key)) {
                            e.preventDefault();
                        }
                    });

                    let newRoutingNumberLabel = document.createElement('label');
                    newRoutingNumberLabel.setAttribute('class', 'hcdeComponentLabel d-inline-block text-start text-truncate form-label col-12');
                    newRoutingNumberLabel.setAttribute('id', 'hcdeRouteNumberLabel');
                    newRoutingNumberLabel.innerText = "Routing Number";
                    hcdeBankDetailCon.appendChild(newRoutingNumberLabel);

                    let hcdeAcctNumCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(2)');
                    hcdeAcctNumCon.setAttribute('id', 'hcdeAccountNumberContainer');
                    hcdeAcctNumCon.setAttribute('class', 'hcdeComponentInputContainer form-floating form-group col-12');

                    let hcdeAcctNumLabel = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(2) > label');
                    hcdeAcctNumLabel.innerText = "";

                    let hcdeAcctNumInput = document.getElementById('bankAccountNumber');
                    hcdeAcctNumInput.setAttribute('class', 'hcdeComponentTextBox form-control');
                    hcdeAcctNumInput.setAttribute('placeholder', '');
                    hcdeAcctNumInput.setAttribute('maxlength', '17');
                    hcdeAcctNumInput.setAttribute('oninput', 'onInputAcctNum()');
                    hcdeAcctNumInput.addEventListener("keypress", e => {
                        if (!digitsOnlyRegExp.test(e.key)) {
                            e.preventDefault();
                        }
                    });

                    let newAcctNumberLabel = document.createElement('label');
                    newAcctNumberLabel.setAttribute('class', 'hcdeComponentLabel d-inline-block text-start text-truncate form-label col-12');
                    newAcctNumberLabel.setAttribute('id', 'hcdeAccountNumberLabel');
                    newAcctNumberLabel.innerText = "Account Number";
                    hcdeAcctNumCon.appendChild(newAcctNumberLabel);

                    let hcdeAcctTypeCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(3)');
                    hcdeAcctTypeCon.setAttribute('id', 'hcdeAccountTypeContainer');
                    hcdeAcctTypeCon.setAttribute('class', 'hcdeComponentInputContainer col-12');

                    let hcdeAcctTypeLabel = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(3) > label');
                    hcdeAcctTypeLabel.innerHTML = '';

                    let hcdeAcctTypeInput = document.getElementById('bankAccountType');
                    hcdeAcctTypeInput.options[0].innerText = 'Bank account type';
                    hcdeAcctTypeInput.setAttribute('onchange', 'onInputAcctType()');

                    let hcdeAmount = document.createElement("div");
                    hcdeAmount.setAttribute('class', 'hcdeComponentInputContainer form-floating form-group col-12')
                    hcdeAmount.setAttribute('id', 'hcdeAmount');

                    let hcdeAmountLab = document.createElement("label");
                    hcdeAmountLab.setAttribute('for', 'PaymentAmount')
                    hcdeAmountLab.setAttribute('class', 'hcdeComponentLabel')

                    let hcdeAmountInput = document.createElement("input");
                    hcdeAmountInput.setAttribute('type', 'text')
                    hcdeAmountInput.setAttribute('name', 'paymentAmt')
                    hcdeAmountInput.setAttribute('class', 'hcdeComponentTextBox form-control')
                    hcdeAmountInput.setAttribute('id', 'PaymentAmount')
                    hcdeAmountInput.setAttribute('maxlength', '10')
                    hcdeAmountInput.setAttribute('autocomplete', 'off')
                    hcdeAmountInput.setAttribute('placeholder', '')
                    hcdeAmountInput.setAttribute('oninput',`onAmount('${bal}')`);
                    hcdeAmountInput.setAttribute('onchange',`setMinVal('${bal}')`);
                    hcdeAmountInput.value = '$' + bal;
                    
                    let hcdeAmountLabel = document.createElement("label");
                    hcdeAmountLabel.setAttribute('class', 'hcdeComponentLabel d-inline-block text-start text-truncate form-label col-12')
                    hcdeAmountLabel.setAttribute('id', 'PaymentAmountLbl')
                    hcdeAmountLabel.innerText = "Payment Amount (Minimum $5.00)";
                    hcdeAmount.appendChild(hcdeAmountLab)
                    hcdeAmount.appendChild(hcdeAmountInput)
                    hcdeAmount.appendChild(hcdeAmountLabel)
                    let hcdeCardBank = document.querySelector('#hcdeAccountTypeContainer')
                    hcdeCardBank.after(hcdeAmount)
                    
                    let hcdeDateAmount = document.createElement("div");
                    hcdeDateAmount.setAttribute('class', 'hcdeComponentInputContainer form-floating form-group col-12')
                    hcdeDateAmount.setAttribute('id', 'hcdeDateAmt');

                    let hcdeDateAmountLab = document.createElement("label");
                    hcdeDateAmountLab.setAttribute('for', 'PaymentDateTime')
                    hcdeDateAmountLab.setAttribute('class', 'hcdeComponentLabel')

                    let hcdeDateTimeInput = document.createElement("input");
                    hcdeDateTimeInput.setAttribute('type', 'text')
                    hcdeDateTimeInput.setAttribute('name', 'paymentDate')
                    hcdeDateTimeInput.setAttribute('class', 'hcdeComponentTextBox form-control')
                    hcdeDateTimeInput.setAttribute('id', 'PaymentDateTime')
                    hcdeDateTimeInput.setAttribute('maxlength', '10')
                    hcdeDateTimeInput.setAttribute('autocomplete', 'off')
                    hcdeDateTimeInput.setAttribute('placeholder', '')
                    hcdeDateTimeInput.setAttribute('disabled', 'true')

                    let hcdeDateTimeLabel = document.createElement("label");
                    hcdeDateTimeLabel.setAttribute('class', 'hcdeComponentLabel d-inline-block text-start text-truncate form-label col-12')
                    hcdeDateTimeLabel.setAttribute('id', 'PaymentAmountLbl')
                    hcdeDateTimeLabel.innerText = formattedDateValue
                    hcdeDateAmount.appendChild(hcdeDateAmountLab)
                    hcdeDateAmount.appendChild(hcdeDateTimeInput)
                    hcdeDateAmount.appendChild(hcdeDateTimeLabel)
                    let hcdeDt = document.querySelector('#hcdeAmount')
                    hcdeDt.after(hcdeDateAmount)

                    let hcdeBankButtonCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentButtonContainer');
                    hcdeBankButtonCon.setAttribute('id', 'hcdeSaveBankButtonContainer');

                    let hcdeSaveBankButton = document.getElementById('hcdeComponentSubmitButton');
                    hcdeSaveBankButton.setAttribute('value', 'Save');

                    // eslint-disable-next-line no-undef
                    checkBankInfo();
                   
                });
            }  
        });
    },
    ModalClose() {
      this.paymentModal.hide();
      this.hcdeReady = false;
      this.hcdeReadyWallet = false;
    },
    ClosePopup() {
      this.popupModal.hide();
    },
    get_nth_suffix(date) {
      switch (date) {
        case 1:
        case 21:
        case 31:
          return "st";
        case 2:
        case 22:
          return "nd";
        case 3:
        case 23:
          return "rd";
        default:
          return "th";
      }
    },
    currentDate() {
      const current = new Date();
      const day = new Date(current).toLocaleString("en-us", {
        weekday: "long",
      });
      const month = current.toLocaleString("en-us", {
        month: "short",
      });
      const ordinal = this.get_nth_suffix(current);
      const today =
        "Today, " + day + ", " + month + " " + current.getDate() + ordinal;
      this.formattedDate = day + ", " + month + " " + current.getDate()
      return today;
    },
    currentPaymentDate() {
      const current = new Date();
      const options = {
        year: "numeric",
        month: "long",
        day: "2-digit",
      };
      return current.toLocaleDateString("en-US", options);
    }
    // twoDecimal (twodecimal) {
    //   if (twodecimal === 'NA') {
    //     return null
    //   }
    //   const num = parseFloat(twodecimal)
    //   return num.toFixed(2)
    // }
  },

  mounted() {
    let attributesData = new Map();
    //spinner to show when page is loading
    attributesData.set('showLoading', true);
    this.$store.dispatch(SET_DATA, {attributes:attributesData});
    localStorage.removeItem('bankLoad');
    localStorage.removeItem('cardLoad');
    // get the reuired values from store to dispaly page 
    this.accountNumber = JSON.parse(this.$store.getters[GET_DATA]("selectedAccountNumber"));
    this.ban = JSON.parse(this.$store.getters[GET_DATA]("AcodAccountNumber"));
    this.firstName = JSON.parse(this.$store.getters[GET_DATA]("firstName"));
    this.lastName = JSON.parse(this.$store.getters[GET_DATA]("lastName"));
    this.emailAddress = JSON.parse(this.$store.getters[GET_DATA]("emailAddress"));
    this.convenienceFeeAmtAch = JSON.parse(this.$store.getters[GET_DATA]("convenienceBankFee"));
    this.convenienceFeeAmtCard = JSON.parse(this.$store.getters[GET_DATA]("convenienceCardFee"));
    this.prepaidAccount = JSON.parse(
      this.$store.getters[GET_DATA]("prepaidAccount")
    );
    this.iframe = JSON.parse(this.$store.getters[GET_DATA]("EpwfIframe"));
    this.prepaidSuspendedAccount = JSON.parse(
      this.$store.getters[GET_DATA]("prepaidInfo")
    );
    this.sessionId = JSON.parse(this.$store.getters[GET_DATA]("EpwfSessionId"));
    attributesData.set('prepaidSuspendedAccount', this.prepaidSuspendedAccount);
    this.showPaNoti = JSON.parse(this.$store.getters[GET_DATA]("pmtArrangementOnAccount"));
    this.balance = JSON.parse(this.$store.getters[GET_DATA]("balanceDue"));
    //? this.twoDecimal(this.$store.getters[GET_DATA]("balanceDue")) : null;
    this.statusMessage = JSON.parse(this.$store.getters[GET_DATA]("status"));
    this.paymentDate = this.getHumanReadbledt(
      JSON.parse(this.$store.getters[GET_DATA]("paymentDate"))
    );
    this.errorMessage = JSON.parse(this.$store.getters[GET_DATA]("errorInfo"));
    attributesData.set("balance", JSON.stringify(this.balance));
    attributesData.set('showLoading', false);
    // Fetching card or bank store value after checking which payment mode is allowed
    this.isAchAllowed = JSON.parse(this.$store.getters[GET_DATA]('isAchAllowed'));
    this.isCardAllowed = JSON.parse(this.$store.getters[GET_DATA]('isCardAllowed'));
    this.$store.dispatch(SET_DATA, {attributes:attributesData});
    this.paymentModal = new Modal(document.getElementById('myModal'),{
        backdrop: 'static',
        keyboard: false
    });
    this.popupModal = new Modal(document.getElementById('confirmationModal'),{
      backdrop: 'static',
      keyboard: false
    });
    this.brimOn = process.env.VUE_APP_BRIM;
    this.env = process.env.VUE_APP_env;
    this.currentDate();
    if(this.brimOn == "true"){
      this.WalletJSEncrypt = links.WalletJSEncrypt;
      this.hcdeWalletServiceApi = links.hcdeWalletServiceApi;
      this.hcdeTokenServiceApi = links.hcdeTokenServiceApi;
      this.hcdeWalletComponent = links.hcdeWalletComponent;
      this.hcdeTokenComponent = links.hcdeTokenComponent;
      this.brim = true;
    }else {
      this.brim = false;
    }
    if (this.iframe !== null && this.errorMessage == null) {
      // GTM for customer eligible for payment
      window.dataLayer?.push({
        event: 'qbp_customer_eligible',
        flow:{
          name: 'qbp',
          step: 'payment'
        },
      });
    } else if (this.iframe === null && this.errorMessage == null) {
      // GTM for an iframe error
      window.dataLayer?.push({
        event: "qbp_error",
        accountNumber: pii(this.accountNumber),
        errorName: "unable to get iframe",
        flow: {
          name: "qbp",
          step: "payment flow"
        },
      });
    } else if (this.errorMessage == 'QBP_PREPAID_NON_SUSPENDED_ACCOUNT') {
      // GTM for prepaid accounts
      window.dataLayer?.push({
        event: 'qbp_error',
        accountNumber: pii(this.accountNumber),
        errorName: 'prepaid',
        flow:{
          name: 'qbp',
          step: 'eligibility'
        }
      })
    } else if (this.errorMessage == 'QBP_BILLING_NAME_UNAVAILABLE') {
      window.dataLayer?.push({
        event: 'qbp_error',
        accountNumber: pii(this.accountNumber),
        errorName: 'billing name unavailable',
        flow:{
          name: 'qbp',
          step: 'payment flow'
        },
      });
    } else if (this.errorMessage == 'QBP_AMOUNT_DUE_UNAVAILABLE') {
      window.dataLayer?.push({
        event: 'qbp_error',
        accountNumber: pii(this.accountNumber),
        errorName: 'amount unavailable',
        flow:{
          name: 'qbp',
          step: 'payment flow'
        },
      });
    }
    // eslint-disable-next-line
    const self = this;
    window.showCardResponse = function (){
      self.createCardPaymentPlan(window.myTokenVar);
    };
    // eslint-disable-next-line
    window.showBankResponse = function(){
      self.createBankPaymentPlan(window.walletInstrumentId);
    };
    window.showBankPopup = function(){
      self.createBankPopupModal(window.walletInstrumentId);
    };
    window.showCardPopup = function(){
      self.createCardPopupModal(window.myTokenVar);
    };
    // eslint-disable-next-line
    window.tokenStatus = function(){
      hcdeStatus.showTokenStatus(window.statusVar);
      self.checkStatus(window.statusVar);
    };
    // eslint-disable-next-line
    window.walletStatus = function(){
      hcdeStatus.showWalletStatus(window.statusVar);
      self.checkStatus(window.statusVar);
    };
    const date = new Date();
    this.paymentProcessedDate = this.formatPaymentProcessedDate(date);
  },
  beforeUnmount() {
      // It's a good practice to clean up by removing the function when the component is destroyed
      delete window.showBankResponse;
      delete window.showCardResponse;
  }
};
</script>
<style scoped>
.large {
  text-align: left;
  --bs-modal-width: 760px;
}
.content-size {
  padding: 1rem;
}
.align-center {
  padding: 1rem;
  display: flex;
  justify-content: center;
}
.flex-component {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  font-size: 16px;
  text-align: left;
  gap: .5rem;
}
.flex-component-button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.content-spacing {
  padding: 1.75rem;
}
.alert-content {
  padding-top: 0.75rem;
}
.flex-entry {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-size: 16px;
  text-align: end;
  margin-left: 1rem;
  gap: 0.75rem;
}
.flex-entry-button {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-size: 16px;
  text-align: end;
  margin-left: 0rem;
  gap: 0.75rem;
}
.flex-label {
  width: 10rem;
}
.flex-span {
  display: flex;
  flex-direction: row;
}
.Submit-Button{
  background-color: #0047bb;
  border-radius: 4px;
  font-weight: 400;
  height: 36px;
  width: 90px;
  padding: 0;
  text-transform: none;
  margin-left: 5px;
  display: inline-block;
  text-decoration: none;
  color: #fff;
  border: none;
}
.cancel-button {
  border: none;
  color: #0047bb;
  text-decoration: underline;
  background: none;
  padding: 0rem;
}
#confirmationModal .modal-body {
  padding-top: 0 !important;
}
.bank-img-section{
  text-align: left;
  padding: 0px 16px;
}
.visa-img, .mastercard-img, .discover-img {
  height: 30px;
  width: 32px;
}
.visa-img {
  margin-left: 0px;
}
.mastercard-img, .discover-img {
  margin-left: 10px;
}
#paymentError {
  margin: 0px 10px 0px 10px;
}
#EpwfNotLoading {
  font-family: Maison Neue, sans-serif;
  font-size: 60pt;
  font-weight: bold;
}
.embed-responsive {
  padding: 0;
}
#pay-bill-info-bar {
  height: auto;
}
.dollars {
  font-size: 24px;
  font-weight: bold;
}
#pay-bill-cris-amount-due {
  height: 30px;
  margin-bottom: 10px;
  margin-right: 0;
}
#payment-due-date-wrapper {
  vertical-align: top;
}
#qbp-left-column-content strong {
  font-weight: bold;
}
#account-identification-wrapper .qbp-account-number {
  color: black;
  font-size: 20px;
  line-height: 1;
  margin-top: 5px;
  font-weight: bold;
  display: inline-block;
}
#payment-due-date {
  font-weight: bold;
}
.pa-info {
  padding: 1rem;
  box-shadow: 0px 1px 4px #00000029;
  border: 1px solid #000000;
  border-radius: 8px;
  height: auto;
  margin: auto auto 1rem auto;
  display: inline-flex;
}
.info-img {
  width: 22px;
  height: 22px;
}
.span-text-pa {
  font-size: 20px;
  color: #14161A;
  letter-spacing: 0.2px;
  margin-left: 0.2rem;
}
#pa-notify-close.btn-close{
    margin-left: 5rem;
    opacity: 1;
  }

  #pa-notify-closee.btn-close:focus{
    box-shadow: none;
  }
@media (min-width: 768px) {
  #pay-bill-info-bar {
    padding-left: 15px;
  }
}
.qbp-dollar {
  font-size: 24px;
  vertical-align: top;
}
#qbp-left-column-content #pay-bill-info-bar {
  margin: 0;
}
.qbp-img {
  opacity: 1;
  width: 270px;
  margin: 4rem 1rem 2rem 1rem;
}
@media (min-width: 250px) and (max-width: 768px) {
  .qbp-img {
    opacity: 1;
    width: 270px;
    margin: 2rem 2rem 2rem 2rem;
  }
}
#left_content{
    padding-right:20px;
    align-content: flex-end;
}
#right_content{
    padding-left: 20px;
    align-content: flex-end;
}
.outside-box {
  background: #faf8f8;
  border: #E9B932 solid 1px;
}
.prepaidAccountError {
  font-size: 14px;
  padding: 5px 0 0 40px;
  background: url("../../assets/images/warning_black_24dp.svg") no-repeat 1px 1px;
  text-align: left;
  margin: 0 20px 20px 0;
  background-position: 5px 5px;
}
#dssp-intro-wrapper h1 {
    color: #14161A;
    font-size: 36px;
    letter-spacing: 0px;
    margin-bottom: 16px;
    opacity: 1;
    padding: 0;
    text-align: center;
}
#dssp-intro-wrapper h2 {
    color: #14161A;
    font-size: 24px;
    letter-spacing: 0.24px;
    opacity: 1;
    text-align: center;
    margin-bottom: 0;
}
.total-amount-banner {
  background: rgba(0, 71, 187, 0.06);
}
.qbp-confirm-details {
    width: 100%;
    margin: 0 auto;
} 
@media (min-width: 767px) {
  .qbp-confirm-details {
    width: 60%;
  }
}
.qbp-details {
  padding: 40px 0 30px 0;
}
.confirm-iframe-error {
  width: 100%;
  margin: 0 auto;
  padding: 30px 15px;
}
.total-amt {
  font-family: MaisonNeue-Medium, sans-serif;
  font-size: 14px;
  color: #14161A;
  position: relative;
  padding-left: 35px;
}
.total-bal {
  color: #14161A;
  font-size: 36px;
  font-family: Maison Neue, sans-serif;
  position: relative;
  padding-left: 20px;
}
.due-on {
  font-size: 14px;
  color: #14161A;
  opacity: 0.7;
}
.dollar {
  font-size: 24px;
  font-family: Maison Neue, sans-serif;
  position: absolute;
  left: 0;
  top: 7px;
}

@media (max-width: 767px) {
  .qbp-banner-img img{
    width: 175px;
  }
}
.confirm-payment {
  font-family: MaisonNeue-Medium, sans-serif;
  font-size: 14px;
  color: #14161A;
  position: relative;
  padding-left: 35px;
}
.confirm-payment:before{
  background: url("../../images/svg/settings_black.svg") no-repeat;
  position: absolute;
  left: 0;
  content: "";
  width: 24px;
  height: 24px;
  top: -3px;
}
.confirm-method {
  color: #14161A;
  font-size: 24px;
}
.confirm-account {
  font-size: 16px;
  margin-bottom: 15px;
}
.total-amt:before {
  background: url("../../images/receipt_black.png") no-repeat;
  position: absolute;
  left: 0;
  content: "";
  width: 24px;
  height: 24px;
  top: -3px;
}
.qbp-account-deatils {
  width: 100%;
  margin: 0 auto;
}
@media (min-width: 767px) {
  .total-amt:before {
    left: -30px;
  }
  .total-amt {
    padding-left:0;
  }
  .confirm-payment:before{
    left: -30px;
  }
  .confirm-payment {
    padding-left:0
  }
  .confirm-iframe-error {
    width: 60%;
    margin: 0 auto;
    padding: 30px 0;
  }
  .qbp-account-deatils {
    width: 55%;
  }
  .qbp-left-details {
    padding-top:30px;
  }
}
.iframe-prepaid-border {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 4px #00000029;
  border-radius: 8px;
  opacity: 1;
  padding: 15px 10px;
}
#susppmtArr {
  padding: 0 20px;
}
.qbp-iframe-container {
  padding-top: 10px;
}
.qbp-payment-cta {
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid #0047BB;
  color: #0047BB;
  margin: 10px;
}
.card-bank-payment {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #00000029;
    border-radius: 8px;
    opacity: 1;
    padding: 15px 10px;
    text-align: center;
}
#myModal .modal-header {
  padding-bottom: 0;
}
#myModal .modal-body {
  padding-top: 0;
  min-height: 150px;
}
@media(max-width:992px) {
  .qbp-payment-cta {
    width: 100%;
    margin: 10px 0;
  }
}
@media(max-width:767px) {
  .flex-component-button {
    gap: 3rem;
  }
  .large{
    --bs-modal-width: 600px !important;
  }
  .flex-label {
    width: 9rem;
  }
  .p {
    font-size: 14px;
  }
  .flex-entry{
    margin-left: -2rem;
    gap: 0.25rem;
  }
  .align-center {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
  .flex-span {
    word-break: break-all;
  }
}
</style>