<template>
    <div class="autopayContainer">
        <div class="row">
            <h1 class="text-decor">Manage AutoPay</h1>
            <div v-bind:class=" isDataAvailable ? 'col-sm-6' : 'col-sm-8'" class="col-xs-12 text-start content-margin-1" style="color: #000000;">
                <p data-test="autopayMessage">AutoPay is secure, convenient and free way to
                automatically
                pay your Bill
                each month. When you use AutoPay you never have to worry about paying your
                bill on time.</p>
                <p data-test="autopaySetup">Once AutoPay is set up, a payment for the total amount due on your bill will be
                automatically submitted on or near your payment due date.</p>
                <a :href="LearnMoreAutoPayURL" class="learn-more bill-header" alt="Learn more about AutoPay"
                    target="_blank">
                    Learn more about AutoPay.
                </a>
            </div>
                <div v-if="isDataAvailable" class="col-sm-6 col-xs-12 content-margin-1">
                    <div class="bill-details-box">
                        <div class="bill-header text-start">
                            <img class="bill-icon" src="../../images/bill_icon.png" alt="bill-icon"/>
                            <h2>YOUR BILL AT-A-GLANCE</h2>
                        </div>
                        <hr />
                        <div v-if="billDate !== null && billDate !== ''">
                            <div class="row bill-header">
                                <div class="col-6 text-start bil-detail-font">BILL DATE</div>
                                <div class="col-6 text-end bil-detail-font">{{ billDate }}</div>
                            </div>
                        </div>
                        <div v-if="billAmount !== null && billAmount !== '' && billAmount !== false">
                            <div class="row bill-header">
                                <div class="col-6 text-start bil-detail-font">BILL AMOUNT</div>
                                <div class="col-6 text-end bil-detail-font">${{ billAmount }}</div>
                            </div>
                        </div>
                        <div v-if="paymentDueDate !== null && paymentDueDate !== ''">
                            <div class="row bill-header">
                                <div class="col-6 text-start bil-detail-font">PAYMENT DUE DATE</div>
                                <div class="col-6 text-end bil-detail-font">{{ paymentDueDate }}</div>
                            </div>
                        </div>
                        <div v-if="currentAmountDue !== null && currentAmountDue !== '' && currentAmountDue !== false">
                            <div class="row bill-header">
                                <div class="col-7 text-start bil-detail-font">CURRENT AMOUNT DUE</div>
                                <div class="col-5 text-end bil-detail-font">${{ currentAmountDue }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="!isDataAvailable" class="col-sm-4 col-xs-12 noData-content-margin">
                    <img class="no-data-image" src="../../images/MicrosoftTeams-image.png" alt="NoData"/>
                </div>
        </div>
    </div>
</template>
<script>
import links from "../../js/links";
import { GET_DATA } from "../../store/sessioncontants";
import { SET_DATA as local_setdata} from "../../store/storecontants";
export default {
    name: 'AutopayDetails',
    data() {
        return {
            billDate: null,
            billAmount: null,
            paymentDueDate: null,
            currentAmountDue: null,
            LearnMoreAutoPayURL: links.LearnMoreAutoPayURL,
            isDataAvailable: false,
            isBrimOn: false
        }
    },
    mounted() {
        this.isBrimOn = process.env.VUE_APP_BRIM;
        if(this.isBrimOn == 'true'){
            this.billDate = this.$store.getters[GET_DATA]('currentInvoiceDate') ? this.getHumanReadbledt(this.$store.getters[GET_DATA]('currentInvoiceDate')) : null;
            this.paymentDueDate = this.$store.getters[GET_DATA]('currentInvoiceDueDate') ? this.getHumanReadbledt(this.$store.getters[GET_DATA]('currentInvoiceDueDate')) : null;
            this.billAmount = this.$store.getters[GET_DATA]('currentInvoiceAmount') ? this.twoDecimal(this.$store.getters[GET_DATA]('currentInvoiceAmount')) : null;
            this.currentAmountDue = this.$store.getters[GET_DATA]('totalDueAmount') ? this.twoDecimal(this.$store.getters[GET_DATA]('totalDueAmount')) : null;
        } else {
            this.billDate = this.$store.getters[GET_DATA]('billDate') ? this.getHumanReadbledt(this.$store.getters[GET_DATA]('billDate')) : null;
            this.paymentDueDate = this.$store.getters[GET_DATA]('paymentDueDate') ? this.getHumanReadbledt(this.$store.getters[GET_DATA]('paymentDueDate')) : null;
            this.billAmount = this.$store.getters[GET_DATA]('billAmount') ? this.twoDecimal(this.$store.getters[GET_DATA]('billAmount')) : null;
            this.currentAmountDue = this.$store.getters[GET_DATA]('currentAmountDue') ? this.twoDecimal(this.$store.getters[GET_DATA]('currentAmountDue')) : null;
        }

        let localData = new Map();
        localData.set('showLoading', true);
        this.$store.dispatch(local_setdata, {attributes:localData});
        if ((this.billDate === null || this.billDate === '') && (this.billAmount === null || this.billAmount === '' || this.billAmount === false) && (this.paymentDueDate === null || this.paymentDueDate === '') && (this.currentAmountDue === null || this.currentAmountDue === '' || this.currentAmountDue === false)) {
            this.isDataAvailable = false;
        } else {
            this.isDataAvailable = true
        }
        localData.set('showLoading', false);
        this.$store.dispatch(local_setdata, {attributes:localData});
    },
    methods: {
        getHumanReadbledt(timeStamp) {
            if (timeStamp == 'NA') {
                return null;
            }
            if (timeStamp.includes('-')) {
                timeStamp = timeStamp.replaceAll('-', '/');
            }
            const monthArr = [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
            ];
            const date = new Date(timeStamp);
            const year = date.getFullYear();
            const month = monthArr[date.getMonth()];
            const newDate = date.getDate();
            const readableDate = month + " " + newDate + ", " + year;
            return readableDate;
            
        },
        twoDecimal(twodecimal) {
            if (twodecimal == 'NA') {
                return null;
            }
            let num = parseFloat(twodecimal)
            let with2Decimals = num.toFixed(2);
            return with2Decimals;
        }
    }
}
</script>
<style>
.bil-detail-font {
    font-size: 12px;
    font-weight: 700;
}

.text-decor {
    color: #14161a;
    font-weight: 500;
    margin-top: 1rem;
}

.bill-details-box {
    background-color: #ffffff;
    height: auto;
    box-shadow: 0px 2px 4px #00000033;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 10px;
    color: #515255;
}

.bill-header {
    margin: 1rem 0 1rem 0;
    display: flex;
}

.content-margin-1 {
    margin: 2rem 0 0 0;
}
.content-margin p {
    line-height: 24px;
}
.learn-more {
    color: #0047bb;
    text-decoration: none !important;
}

.bill-icon {
    height: 15px !important;
    width: 15px !important;
    margin: 0 0.5rem 0 0.5rem;
    border-radius: 0% !important;
    background: #ffffff !important;
}

@media(min-width: 992px) {
    .autopay-box {
        width: 70%;
        height: 100%;
        border-radius: 5px;
    }
}

.text-decor-left {
    color: #14161a;
    font-weight: 500;
    margin-top: 1rem;
    font-size: 24px;
    text-align: left;
}

.upperCase {
    text-transform: uppercase;
}


@media(min-width: 576px) {
    .noData-content-margin {
        margin: 2rem 0 2rem 0;
    }
}
.bill-details-box h2 {
    display: inline-block;
    font-size: 14px;
}
</style>