<template>
  <div class="qbp-confirm">
    <QBPConfirm/>
    <div v-show="this.prepaidAccount == true && this.prepaidSuspendedAccount == false" class="container-fluid">
      <div class="row row-margin">
        <div class="col-12">
          <QuickPayRightBar/>
        </div>
      </div>
    </div>
    <link rel="stylesheet" type="text/css" :href="this.hcdeTokenTheme" :integrity="this.hcdeTokenThemeHash" crossorigin="anonymous"/>
    <link rel="stylesheet" type="text/css" :href="this.hcdewalletTheme" :integrity="this.hcdewalletThemeHash" crossorigin="anonymous" />
  </div>
</template>
  
<script>
  import QBPConfirm from '../components/qbp/QBPConfirm.vue'
  import QuickPayRightBar from '../components/qbp/quickPayRightBar.vue'
  import { GET_DATA } from '../store/storecontants'
  import links from '../js/links';
  import constants from '../js/constants';
  export default {
    name: 'Confirm',
    components: {
      QBPConfirm,
      QuickPayRightBar
    },
    data() {
      return {
        prepaidAccount: null,
        prepaidSuspendedAccount: null,
        isBrimOn: false,
        hcdeTokenTheme: null,
        hcdewalletTheme: null,
        hcdeTokenThemeHash: null,
        hcdewalletThemeHash: null
      }
    },
    mounted() {
      this.prepaidAccount = JSON.parse(this.$store.getters[GET_DATA]("prepaidAccount"));
      this.prepaidSuspendedAccount = JSON.parse(this.$store.getters[GET_DATA]("prepaidInfo"));
      this.isBrimOn = process.env.VUE_APP_BRIM;
      if(this.isBrimOn == 'true'){
        this.hcdeTokenTheme = links.hcdeTokenTheme;
        this.hcdewalletTheme = links.hcdewalletTheme;
        this.hcdeTokenThemeHash = constants.hcdeTokenThemeHash;
        this.hcdewalletThemeHash = constants.hcdewalletThemeHash;
      }
    }
  }
</script>

<style scoped>
  .qbp-confirm {
      font-family: Maison Neue,sans-serif;
      margin-top: -20px;
      padding-top: 20px;
      background: #FAFAFA;
  }
  #app .qbp-confirm {
      text-align: left;
  }
  .okBtn {
      padding-right: 20px;
      padding-bottom: 20px;
  }
  .okBtn a{
      color: #fff ;
      text-decoration: none ;
  }
  .okBtn a:hover{
      color: #fff;
      text-decoration: none;
  }
  .modalContent {
      padding-bottom: 0;
      padding-top: 35px;
  }
  .row-margin {
    padding-left: 10%;
    background: #ebeff6 0% 0% no-repeat padding-box;
    margin-top: 20px;
    display: flex;
  }
  @media (min-width: 768px) {
    .row-margin {
      display: flex;
    }
  }
</style>