<template>
  <div v-if="this.isEnrolled && this.isPostpaid && this.isBrimOn" >
    <div class="container d-flex justify-content-center autopay-box"><autopay-postpaid :customerData="this.customerData"/></div>
  </div>
  <div v-else-if="this.isEnrolled && this.isPrepaid && this.isBrimOn">
    <div class="container d-flex justify-content-center autopay-box"><autopay-prepaid :customerData="this.customerData"/></div>
  </div>
  <div v-else-if="(this.pageLoaded && !this.isEnrolled) || (this.pageLoaded && !this.isBrimOn)">
    <div class="container d-flex justify-content-center autopay-box"><autopay-details/></div>
    <div class="container d-flex justify-content-center autopaysuccessbox"><manage-autopay :customerData="this.customerData"/></div>
  </div> 
  <link rel="stylesheet" type="text/css" :href="this.hcdeTokenTheme" :integrity="this.hcdeTokenThemeHash" crossorigin="anonymous"/>
  <link rel="stylesheet" type="text/css" :href="this.hcdewalletTheme" :integrity="this.hcdewalletThemeHash" crossorigin="anonymous" />
</template>
<script>
import ManageAutopay from '../components/Autopay/manageAutopay.vue';
import AutopayDetails from '../components/Autopay/AutopayDetails.vue';
import AutopayPrepaid from '../components/Autopay/autopayPrepaid.vue';
import AutopayPostpaid from '../components/Autopay/autopayPostpaid.vue';
import links from '../js/links';
import axios from 'axios';
import constants from '../js/constants';
import { GET_DATA, SET_DATA } from "../store/sessioncontants";
import { SET_DATA as local_setdata} from "../store/storecontants";

export default {
  name: 'ManageAP',
  components: {
    ManageAutopay,
    AutopayDetails,
    AutopayPrepaid,
    AutopayPostpaid
  },
  data() {
    return {
      accountNumber: null,
      autopayEnrollStatus: null,
      isEnrolled: false,
      isPrepaid: false,
      isPostpaid: false,
      isBrimOn: false,
      pageLoaded: false,
      hcdeTokenTheme: null,
      hcdewalletTheme: null,
      hcdeTokenThemeHash: null,
      hcdewalletThemeHash: null,
      customerData: null,
      achAllowed: true,
      cardAllowed: true
    }
  },
  mounted() {
    let localData = new Map();
    localData.set('showLoading', true);
    this.$store.dispatch(local_setdata, {attributes:localData});
    this.accountNumber = this.$store.getters[GET_DATA]('accountNumber');
    this.isBrimOn = process.env.VUE_APP_BRIM;
    if(this.isBrimOn == 'true'){
      this.isBrimOn = true;
      this.hcdeTokenTheme = links.hcdeTokenTheme;
      this.hcdewalletTheme = links.hcdewalletTheme;
      this.hcdeTokenThemeHash = constants.hcdeTokenThemeHash;
      this.hcdewalletThemeHash = constants.hcdewalletThemeHash;
      this.getAutopayCustomerDetails();
    } else {
      this.isBrimOn = false;
      this.pageLoaded = true;
      this.isEnrolled = false;
      this.isPrepaid = false;
      this.isPostpaid = false;
      localData.set('showLoading', false);
      this.$store.dispatch(local_setdata, {attributes:localData});
    }
  },
  methods: {
    async getAutopayCustomerDetails() {
      let localData = new Map();
      let attributesData = new Map();
      try {
        const params = { ban: this.accountNumber };
        const res = await axios.get(constants.BrimAutopayCustomerDetails, { params });
        if (res.data) {
          this.autopayEnrollStatus = res.data.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].autopayEnrollmentInfo.autopayStatus ? res.data.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].autopayEnrollmentInfo.autopayStatus : '';
          const achFlag = JSON.stringify(res.data.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.achAllowedFlag);
          const cardFlag = JSON.stringify(res.data.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.cardAllowedFlag);
          this.achAllowed = (achFlag === 'true');
          this.cardAllowed = (cardFlag === 'true');
          this.customerData = JSON.stringify(res.data);      
          if (this.autopayEnrollStatus == 'ENROLLED' && this.accountNumber.includes('PPB')) {
            this.isEnrolled = true;
            this.isPrepaid = true;
            this.isPostpaid = false;
          } else if (this.autopayEnrollStatus == 'ENROLLED' && !this.accountNumber.includes('PPB')) {
            this.isEnrolled = true;
            this.isPrepaid = false;
            this.isPostpaid = true;
          } else {
            this.pageLoaded = true;
            this.isEnrolled = false;
            this.isPrepaid = false;
            this.isPostpaid = false;
          }

          localData.set('showLoading', false);
          this.$store.dispatch(local_setdata, {attributes:localData});
          attributesData.set("currentInvoiceDate", res.data.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].amountDueInfo.currentInvoiceDate);
          attributesData.set("currentInvoiceDueDate", res.data.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].amountDueInfo.currentInvoiceDueDate);
          attributesData.set("currentInvoiceAmount", res.data.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].amountDueInfo.currentInvoiceAmount);
          attributesData.set("totalDueAmount", res.data.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].amountDueInfo.totalDueAmount);
          attributesData.set("customerDetails", res.data);
          attributesData.set("autopayEnrollStatus", this.autopayEnrollStatus);
          attributesData.set('isCardAllowed', this.cardAllowed);
          attributesData.set('isAchAllowed', this.achAllowed);
          this.$store.dispatch(SET_DATA, { attributes: attributesData });
        } else{
          this.pageLoaded = true;
          this.isEnrolled = false;
          this.isPrepaid = false;
          this.isPostpaid = false;
          this.customerData = null; 
          attributesData.set("brimStatus", 'ERROR');
          attributesData.set('customerDetails', null);
          this.$store.dispatch(SET_DATA, { attributes: attributesData });
          localData.set('showLoading', false);
          this.$store.dispatch(local_setdata, {attributes:localData});
        }
      } catch (err) {
        console.log(err);
        this.pageLoaded = true;
        this.isEnrolled = false;
        this.isPrepaid = false;
        this.isPostpaid = false;
        this.customerData = null;
        attributesData.set("brimStatus", 'ERROR');
        attributesData.set('customerDetails', null);
        this.$store.dispatch(SET_DATA, { attributes: attributesData });
        localData.set('showLoading', false);
        this.$store.dispatch(local_setdata, {attributes:localData});
      }
    }
  }
};
</script>
<style>
.autopay-box {
  width: 58%;
  height: 100%;
  border-radius: 5px;
}

.autopaysuccessbox {
  margin-top: 1rem;
  width: 58%;
  height: 100%;
  border-radius: 5px;
}

@media(max-width: 979px) {
  .autopay-box {
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }

  .autopaysuccessbox {
    margin-top: 1rem;
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }
}
</style>
