<template>
    <div class="container">
        <div class="row">
            <div v-if="showLoader"></div>
            <div v-if="iframeSession !== null" class="padding0">
                <h3 class="text-decor-left padding0">Your AutoPay Details</h3>
                <div class="autopay-iframe">
                    <iframe id="autoPayIframe" title="autopayIframe" :src="this.autopayIframeUrl" width="100%"
                        style="height: 550px; width: 100%; border: none; overflow:hidden"
                        :style="{height: autopayiframeHeight + 'px'}" :onload="this.onAutopayIframeResolutionchange">
                        <p>Your browser does not support iframes.</p>
                    </iframe>
                </div>
            </div>
            <div v-else-if="iframeSession === null && !showLoader && !this.brim" class="padding0">
                <h1 class="text-decor-left padding0">Your AutoPay Details</h1>
                <div class="autopay-iframe">
                    <auto-pay />
                </div>
            </div>

            <div v-if="this.brim">
                <div v-if="this.brimStatus == 'ERROR' || this.sessionId === null">
                    <auto-pay />
                </div>
                <div v-else-if="this.customerDataNotPresent">
                    <h2>Whoops! Something didn't go quite as expected.</h2>
                    <p>Please try again later</p>
                </div>
                <div v-else class="text-decor-left">
                    <h3 class="padding0">Your AutoPay Details</h3>
                    <p class="autopay-enroll-status">Autopay Status: Not Enrolled</p>
                    <button v-if="isAchAllowed" type="button" class="btn setup-button" tabindex="0"
                        @click="showBankDetails()">Set up with bank account</button>
                    <button v-if="isCardAllowed" type="button" class="btn setup-button" tabindex="0"
                        @click="showCardDetails()">Set up with debit/credit account</button>
                </div>
            </div>

            <!-- Modal -->
            <div id="myModal" class="modal fade manage-wallet-bank-card" role="dialog">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header no-border">
                            <h5 v-if="this.bankOptionTitle" class="modal-title">Enter bank account info</h5>
                            <h5 v-if="this.cardOptionTitle">Enter card info</h5>
                            <button type="button" class="btn" @click="modalClose()" aria-label="Close">
                                <img src="../../images/svg/icon-navigation-close_24px.svg" alt="outline-info" />
                            </button>
                        </div>
                        <div class="hcde-comp">
                            <hcde-tokenization v-if="this.hcdeReady" :env="this.env" clientId="MYA_PAY" :sessionId="this.sessionId" callbackFunction="showCardPopup" callbackField="myTokenVar" statusFunction="tokenStatus" statusField="statusVar" heartBeatFrequency="60" srcSysTransId="307902021108">Loading...</hcde-tokenization>
                            <hcde-wallet v-if="this.hcdeReadyWallet" :env="this.env" clientId="MYA_PAY" :sessionId="this.sessionId" callbackFunction="showBankPopup" callbackRefNumField="walletRefNum" callbackInstrumentField="walletInstrumentId" statusFunction="walletStatus" statusField="statusVar" heartBeatFrequency="60" srcSysTransId="1234567890">Loading...</hcde-wallet>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Confirmation Modal  -->
            <div id="confirmationModal" class="modal large" role="dialog">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content content-size">
                        <div class="modal-body">
                            <div class="hcde-comp">
                                <p>The full amount of your bill will be automatically deducted using this payment method.</p>
                            </div>
                            <div class="align-center">
                                <div class="flex-component">
                                    <div v-if="this.selectedOption === 'Card'" class="flex-entry">
                                        <p class="flex-label">Card Number:</p>
                                        <p class="flex-span"> {{ this.enteredCardNumber }}</p>
                                    </div>
                                    <div v-if="this.selectedOption === 'Card'" class="flex-entry">
                                        <p class="flex-label">Process as:</p>
                                        <p class="flex-span"> Credit/Debit card</p>
                                    </div>
                                    <div v-if="this.selectedOption === 'Card'" class="flex-entry">
                                        <p class="flex-label">Expiration:</p>
                                        <p class="flex-span"> {{ this.hcdeCardExpiryMonth }} / {{
                                            this.hcdeCardExpiryYear }}</p>
                                    </div>
                                    <div v-if="this.selectedOption === 'Card'" class="flex-entry">
                                        <p class="flex-label">Billing Zip:</p>
                                        <p class="flex-span"> {{ this.hcdeZipCode }}</p>
                                    </div>
                                    <div v-if="this.selectedOption === 'Card'" class="flex-entry">
                                        <p class="flex-label">Currency:</p>
                                        <p class="flex-span"> USD </p>
                                    </div>
                                    <div v-if="this.selectedOption === 'Bank'" class="flex-entry">
                                        <p class="flex-label">Routing Number:</p>
                                        <p class="flex-span"> {{ this.enteredRoutingNumber }}</p>
                                    </div>
                                    <div v-if="this.selectedOption === 'Bank'" class="flex-entry">
                                        <p class="flex-label">Account Number:</p>
                                        <p class="flex-span"> {{ this.enteredAccountNumber }}</p>
                                    </div>
                                    <div v-if="this.selectedOption === 'Bank'" class="flex-entry">
                                        <p class="flex-label">Bank Account Type:</p>
                                        <p class="flex-span"> {{ this.enteredBankType }} Account</p>
                                    </div>
                                    <div v-if="this.selectedOption === 'Bank'" class="flex-entry">
                                        <p class="flex-label">Currency:</p>
                                        <p class="flex-span"> USD </p>
                                    </div>
                                </div>
                            </div>
                            <div class="hcde-comp">
                                <p class="alert-content">Does this information look correct or do you need to
                                    <button type="button" class="cancel-button" @click="ClosePopup()">Make Changes</button>?
                                </p>
                            </div>
                            <div class="flex-component-button content-spacing">
                                <div class="flex-entry-button">
                                    <button class="Submit-Button" @click="ClosePopup()">Cancel</button>
                                </div>
                                <div class="flex-entry-button">
                                    <div v-if="this.selectedOption === 'Card'" class="flex-entry">
                                        <button class="Submit-Button" @click="submitCardAutopay(this.myTokenVar)">Save</button>
                                    </div>
                                    <div v-if="this.selectedOption === 'Bank'" class="flex-entry">
                                        <button class="Submit-Button" @click="submitBankAutopay(this.walletInstrumentId)">Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </div>
    </div>
</template>

<script>
import { Modal } from "bootstrap/dist/js/bootstrap.bundle";
import axios from 'axios';
import constants from '../../js/constants';
import { GET_DATA, SET_DATA } from "../../store/sessioncontants";
import { SET_DATA as local_setdata, GET_DATA as local_getdata} from "../../store/storecontants";
import AutoPay from '../ErrorMessages/autoPay.vue';
import links from '../../js/links'
import hcdeStatus from "../../js/hcdestatus";

export default {
    name: 'ManageAutopay',
    components: {
        AutoPay
    },
    props: ['customerData'],
    data() {
        return {
            autopayIframeUrl: null,
            iframeSession: null,
            autopayiframeHeight: 770,
            autopayIframechange: 0,
            showLoader: true,
            customerDataNotPresent: false,
            brim: false,
            brimOn: false,
            epwf: false,
            autopayModal: null,
            removeModal: null,
            brimStatus: '',
            autopayEnrollStatus: '',
            manageModal: null,
            hcdeReady: false,
            hcdeReadyWallet: false,
            WalletJSEncrypt: null,
            hcdeTokenServiceApi: null,
            hcdeWalletServiceApi: null,
            hcdeWalletComponent: null,
            hcdeTagString: null,
            hcdeTokenComponent: null,
            customerDetails: null,
            autopaySubmitRequest: null,
            isCardAllowed: true,
            isAchAllowed: true,
            enteredAccountNumber: null,
            enteredRoutingNumber: null,
            enteredBankType: null,
            selectedOption: null
        }
    },
    updated() {
    this.isBrimOn = process.env.VUE_APP_BRIM;
    if(this.isBrimOn == 'true'){
        this.createHcdeTagListener()
        }
    },
    mounted() {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'ap_manage',
            flow: {
                name: 'autopay',
                step: 'manage'
            }
        });
        localStorage.clear();
        let localData = new Map();
        localData.set('showLoading', true);
        this.$store.dispatch(local_setdata, {attributes:localData});
        this.autopayModal = new Modal(document.getElementById('myModal'),{
                backdrop: 'static',
                keyboard: false
            });
        this.brimOn = process.env.VUE_APP_BRIM;
        this.env = process.env.VUE_APP_env;
        this.token = this.$store.getters[GET_DATA]("token");
        this.autopayEnrollStatus = this.$store.getters[GET_DATA]("autopayEnrollStatus");
        const achFlag = JSON.stringify(this.$store.getters[GET_DATA]('isAchAllowed'));
        const cardFlag = JSON.stringify(this.$store.getters[GET_DATA]('isCardAllowed'));
        this.isAchAllowed = (achFlag === 'true');
        this.isCardAllowed = (cardFlag === 'true');
        if(this.customerData !== null || this.customerData !== '') {
            this.customerDetails = JSON.parse(this.customerData);
            this.customerDataNotPresent = false;
        } else if (this.customerDetails == '' || this.customerDetails== null || this.customerDetails.status == 1 ) { this.customerDataNotPresent = true; }
        if(this.brimOn == "true") {
            this.WalletJSEncrypt = links.WalletJSEncrypt;
            this.hcdeTokenServiceApi = links.hcdeTokenServiceApi;
            this.hcdeWalletServiceApi = links.hcdeWalletServiceApi;
            this.hcdeWalletComponent = links.hcdeWalletComponent;
            this.hcdeTokenComponent = links.hcdeTokenComponent;
            this.brim = true
            this.epwf = false
            this.getAutopayBrimDetails();
            let attributesData = new Map();
            attributesData.set("walletRefNumberBrim", this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].autopayEnrollmentInfo.autopayWalletId);
            this.$store.dispatch(SET_DATA, { attributes: attributesData });
        } else {
            this.brim = false
            this.epwf = true
            this.getAutopayDetails();
        }
        // eslint-disable-next-line
        const self = this;
        this.popupModal = new Modal(document.getElementById('confirmationModal'),{
            backdrop: 'static',
            keyboard: false
        });
        window.showBankPopup = function(){
          self.createBankPopupModal(window.walletInstrumentId);
        };
        window.showCardPopup = function(){
          self.createCardPopupModal(window.myTokenVar);
        };
        window.showCardResponse = function (){
            self.submitCardAutopay(window.myTokenVar);
        };
        // eslint-disable-next-line
        window.showBankResponse = function(){
            //alert("HCDE walletInstrumentId= "+window.walletInstrumentId);
            // self.createBank(window.walletInstrumentId);
            self.submitBankAutopay(window.walletInstrumentId);
        };
        // eslint-disable-next-line
        window.tokenStatus = function(){
            hcdeStatus.showTokenStatus(window.statusVar);
            self.checkStatus(window.statusVar);
        };
        // eslint-disable-next-line
        window.walletStatus = function(){
            hcdeStatus.showWalletStatus(window.statusVar);
            self.checkStatus(window.statusVar);
        };
    },
    beforeUnmount() {
        // It's a good practice to clean up by removing the function when the component is destroyed
        delete window.showBankResponse;
        delete window.showCardResponse;
        delete window.tokenStatus;
        delete window.walletStatus;
    },
    methods: {
        showBankDetails() {
            let localData = new Map();
            localData.set('showLoading', true);
            this.$store.dispatch(local_setdata, {attributes:localData});
            this.bankOptionTitle = true;
            this.cardOptionTitle = false;
            this.autopayModal.show();
            this.bankOption();
            localData.set('showLoading', false);
            this.$store.dispatch(local_setdata, {attributes:localData});
        },
        showCardDetails() {
            let localData = new Map();
            localData.set('showLoading', true);
            this.$store.dispatch(local_setdata, {attributes:localData});
            this.bankOptionTitle = false;
            this.cardOptionTitle = true;
            this.autopayModal.show();
            this.cardOption();
            localData.set('showLoading', false);
            this.$store.dispatch(local_setdata, {attributes:localData});
        },
        removeJSfile(oldfilename) {
            let targetelement = 'script';
            let targetattr = 'src';
            let allsuspects = document.getElementsByTagName(targetelement);
            for (let i = allsuspects.length; i >= 0; i--) {
                //search backwards within nodelist for matching elements to remove
                if (allsuspects[i] && allsuspects[i].getAttribute(targetattr) != null && allsuspects[i].getAttribute(targetattr).indexOf(oldfilename) != -1) {
                    allsuspects[i].parentNode.removeChild(allsuspects[i]);
                }
            }
        },
        checkStatus(status) {
            if(status.includes("EXPIRED")){
                this.modalClose()
                this.$router.push({
                    name: "FailureAP",
                    params: {showTimeout: 'timeOut'},
                });
            }
        },
        async createHcdeTagListener() {
            const scripts = [
                this.WalletJSEncrypt,
                this.hcdeTokenServiceApi,
                this.hcdeWalletServiceApi
            ];
            const integrity = {
                [this.WalletJSEncrypt]: constants.jsencryptHash,
                [this.hcdeTokenServiceApi]: constants.hcdeTokenServiceApiHash,
                [this.hcdeWalletServiceApi]: constants.hcdeWalletServiceApiHash
            };
            scripts.forEach(script => {
                let tag = document.head.querySelector(`[src="${ script }"`);
                if (!tag) {
                    tag = document.createElement("script");
                    tag.setAttribute("src", script);
                    tag.setAttribute("type", 'text/javascript');
                    tag.setAttribute("integrity", integrity[script]);
                    tag.setAttribute("crossorigin",'anonymous');
                    document.head.appendChild(tag); 
                }
            });
        },
        bankOption() {
            this.hcdeReady = false;
            this.hcdeReadyWallet = true;
            const bankscripts = [
                this.hcdeWalletComponent				
            ];
            bankscripts.forEach(script => {
                let scriptFound = document.head.querySelector(`[src="${ script }"`) ? true : false;
                if (scriptFound) {
                    this.removeJSfile(script);
                }
                let tag = document.head.querySelector(`[src="${ script }"`);
                if (!tag) {
                    tag = document.createElement("script");
                    tag.setAttribute("src", script);
                    tag.setAttribute("type", 'text/javascript');
                    tag.setAttribute("integrity", constants.hcdeWalletComponentHash);
                    tag.setAttribute("crossorigin",'anonymous');
                    document.head.appendChild(tag); 
                    tag.addEventListener('load', function () {
                        // eslint-disable-next-line no-undef
                        this.hcdeTagString = new HCDEWalletServiceComponent();

                        const digitsOnlyRegExp = /^[0-9]*$/;

                        let hcdeBankDetailCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(1)');
                        hcdeBankDetailCon.setAttribute('id', 'hcdeBankDetailContainer');
                        hcdeBankDetailCon.setAttribute('class', 'hcdeComponentInputContainer form-floating form-group col-12');

                        let hcdeRouteNumLabel = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(1) > label');
                        hcdeRouteNumLabel.innerText = "";

                        let hcdeRouteNumInput = document.getElementById('bankRoutingNumber');
                        hcdeRouteNumInput.setAttribute('class', 'hcdeComponentTextBox form-control');
                        hcdeRouteNumInput.setAttribute('placeholder', ' ');
                        hcdeRouteNumInput.setAttribute('maxlength', '9');
                        hcdeRouteNumInput.setAttribute('oninput', 'onChangeRouteNum()');
                        hcdeRouteNumInput.addEventListener("keypress", e => {
                            if (!digitsOnlyRegExp.test(e.key)) {
                                e.preventDefault();
                            }
                        });

                        let newRoutingNumberLabel = document.createElement('label');
                        newRoutingNumberLabel.setAttribute('class', 'hcdeComponentLabel d-inline-block text-start text-truncate form-label col-12');
                        newRoutingNumberLabel.setAttribute('id', 'hcdeRouteNumberLabel');
                        newRoutingNumberLabel.innerText = "Routing Number";
                        hcdeBankDetailCon.appendChild(newRoutingNumberLabel);

                        let hcdeAcctNumCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(2)');
                        hcdeAcctNumCon.setAttribute('id', 'hcdeAccountNumberContainer');
                        hcdeAcctNumCon.setAttribute('class', 'hcdeComponentInputContainer form-floating form-group col-12');

                        let hcdeAcctNumLabel = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(2) > label');
                        hcdeAcctNumLabel.innerText = "";

                        let hcdeAcctNumInput = document.getElementById('bankAccountNumber');
                        hcdeAcctNumInput.setAttribute('class', 'hcdeComponentTextBox form-control');
                        hcdeAcctNumInput.setAttribute('placeholder', '');
                        hcdeAcctNumInput.setAttribute('maxlength', '17');
                        hcdeAcctNumInput.setAttribute('oninput', 'onChangeAcctNum()');
                        hcdeAcctNumInput.addEventListener("keypress", e => {
                            if (!digitsOnlyRegExp.test(e.key)) {
                                e.preventDefault();
                            }
                        });

                        let newAcctNumberLabel = document.createElement('label');
                        newAcctNumberLabel.setAttribute('class', 'hcdeComponentLabel d-inline-block text-start text-truncate form-label col-12');
                        newAcctNumberLabel.setAttribute('id', 'hcdeAccountNumberLabel');
                        newAcctNumberLabel.innerText = "Account Number";
                        hcdeAcctNumCon.appendChild(newAcctNumberLabel);

                        let hcdeAcctTypeCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(3)');
                        hcdeAcctTypeCon.setAttribute('id', 'hcdeAccountTypeContainer');
                        hcdeAcctTypeCon.setAttribute('class', 'hcdeComponentInputContainer col-12');

                        let hcdeAcctTypeLabel = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(3) > label');
                        hcdeAcctTypeLabel.innerHTML = '';

                        let hcdeAcctTypeInput = document.getElementById('bankAccountType');
                        hcdeAcctTypeInput.options[0].innerText = 'Bank account type';
                        hcdeAcctTypeInput.setAttribute('onchange', 'onChangeAcctType()');

                        let hcdeBankButtonCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentButtonContainer');
                        hcdeBankButtonCon.setAttribute('id', 'hcdeSaveBankButtonContainer');

                        let hcdeSaveBankButton = document.getElementById('hcdeComponentSubmitButton');
                        hcdeSaveBankButton.setAttribute('value', 'Save');

                        // eslint-disable-next-line no-undef
                        checkBankInfo();
                    });
                }
                setTimeout(() => (
                    this.bankOptionTitle = true
                ), 135);   
            });
        },
        cardOption() {
            this.hcdeReady = true;
            this.hcdeReadyWallet = false;  
            const cardscripts = [
                this.hcdeTokenComponent				
            ];
            cardscripts.forEach(script=>{
                let scriptFound = document.head.querySelector(`[src="${ script }"`) ? true : false;
                if (scriptFound) {
                    this.removeJSfile(script);
                }
                let tag = document.head.querySelector(`[src="${ script }"`);
                if (!tag) {
                    tag = document.createElement("script");
                    tag.setAttribute("src", script);
                    tag.setAttribute("type", 'text/javascript');
                    tag.setAttribute("integrity", constants.hcdeTokenComponentHash);
                    tag.setAttribute("crossorigin",'anonymous');
                    document.head.appendChild(tag);
                    tag.addEventListener('load', function () {
                        // eslint-disable-next-line no-undef
                        this.hcdeTagString = new HCDETokenServiceComponent();
                        
                        const digitsOnlyRegExp = /^[0-9]*$/;

                        let hcdeCardNumCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(1)');
                        hcdeCardNumCon.setAttribute('id', 'hcdeCardNumberContainer');
                        hcdeCardNumCon.setAttribute('class', 'hcdeComponentInputContainer form-floating form-group col-12');

                        let hcdeCardNumLabel = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(1) > label');
                        hcdeCardNumLabel.innerText = "";

                        let hcdeCardNumInput = document.getElementById('hcdeCardNumber');
                        hcdeCardNumInput.setAttribute('class', 'hcdeComponentTextBox form-control');
                        hcdeCardNumInput.setAttribute('placeholder', ' ');
                        hcdeCardNumInput.setAttribute('maxlength', '16');
                        hcdeCardNumInput.setAttribute('oninput', 'onChangeCardNum()');
                        hcdeCardNumInput.addEventListener("keypress", e => {
                            if (!digitsOnlyRegExp.test(e.key)) {
                                e.preventDefault();
                            }
                        });

                        let newCardNumLabel = document.createElement('label');
                        newCardNumLabel.setAttribute('class', 'hcdeComponentLabel d-inline-block text-start text-truncate form-label col-12');
                        newCardNumLabel.setAttribute('id', 'hcdeCardNumberLabel');
                        newCardNumLabel.innerText = "Card Number";
                        hcdeCardNumCon.appendChild(newCardNumLabel);
                        
                        let hcdeExpMonthCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(2)');
                        hcdeExpMonthCon.setAttribute('id', 'hcdeExpMonthContainer');
                        hcdeExpMonthCon.setAttribute('class', 'hcdeComponentInputContainer col-6 pe-1');

                        let hcdeExpMonthLabel = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(2) > label');
                        hcdeExpMonthLabel.innerText = "";

                        let hcdeExpMonthInput = document.getElementById('hcdeCardExpiryMonth');
                        hcdeExpMonthInput.options[0].innerText = 'Expiration Month';
                        hcdeExpMonthInput.setAttribute('onchange', 'onChangeExpMth()');

                        let hcdeExpYearCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(3)');
                        hcdeExpYearCon.setAttribute('id', 'hcdeExpYearContainer');
                        hcdeExpYearCon.setAttribute('class', 'hcdeComponentInputContainer col-6 ps-1');

                        let hcdeExpYearLabel = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(3) > label');
                        hcdeExpYearLabel.innerText = "";
                        
                        let hcdeExpYearInput = document.getElementById('hcdeCardExpiryYear');
                        hcdeExpYearInput.options[0].innerText = 'Expiration Year';
                        hcdeExpYearInput.setAttribute('onchange', 'onChangeExpYr()');

                        let hcdeZipCodeCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(4)');
                        hcdeZipCodeCon.setAttribute('id', 'hcdeZipCodeContainer');
                        hcdeZipCodeCon.setAttribute('class', 'hcdeComponentInputContainer form-floating form-group col-6 pe-1');

                        let hcdeZipCodeLabel = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(4) > label');
                        hcdeZipCodeLabel.innerText = "";

                        let hcdeZipCodeInput = document.getElementById('hcdeZipCode');
                        hcdeZipCodeInput.setAttribute('class', 'hcdeComponentTextBox form-control');
                        hcdeZipCodeInput.setAttribute('placeholder', ' ');
                        hcdeZipCodeInput.setAttribute('maxlength', '5');
                        hcdeZipCodeInput.setAttribute('oninput', 'onChangeZipCode()');
                        hcdeZipCodeInput.addEventListener("keypress", e => {
                            if (!digitsOnlyRegExp.test(e.key)) {
                                e.preventDefault();
                            }
                        });

                        let newZipCodeLabel = document.createElement('label');
                        newZipCodeLabel.setAttribute('class', 'hcdeComponentLabel d-inline-block text-start text-truncate form-label col-12');
                        newZipCodeLabel.setAttribute('id', 'hcdeZipCodeLabel');
                        newZipCodeLabel.innerText = "Zip Code";
                        hcdeZipCodeCon.appendChild(newZipCodeLabel);

                        let hcdeButtonCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentButtonContainer');
                        hcdeButtonCon.setAttribute('id', 'hcdeSaveButtonContainer');

                        let hcdeSaveButton = document.getElementById('hcdeComponentSubmitButton');
                        hcdeSaveButton.setAttribute('value', 'Save');

                        // eslint-disable-next-line no-undef
                        checkCardInfo();
                    });
                }
                setTimeout(() => (
                    this.cardOptionTitle = true
                ), 135);  
            });
        },
        async getAutopayBrimDetails() {
            let localData = new Map();
            try {
                let params = {
                    accountNumber: this.$store.getters[GET_DATA]('accountNumber'),
                    profileId: this.$store.getters[GET_DATA]('profileId'),
                    emailAddress: this.$store.getters[GET_DATA]('emailAddress') ? this.$store.getters[GET_DATA]('emailAddress') : this.$store.getters[GET_DATA]('emailaddress'),
                };
                const res = await axios.post(constants.AutopayOrchestratorUrl, params);
                if(res.data.epwfSession.sessionId) {     
                    this.sessionId = res.data.epwfSession.sessionId;
                    localData.set('showLoading', false);
                    this.$store.dispatch(local_setdata, {attributes:localData});
                } else {
                    this.brimStatus = 'ERROR';
                    localData.set('showLoading', false);
                    this.$store.dispatch(local_setdata, {attributes:localData});
                }
            } catch (err) {
                console.log(err);
                this.brimStatus = 'ERROR';
                localData.set('showLoading', false);
                this.$store.dispatch(local_setdata, {attributes:localData});
            }
        },
        async getAutopayDetails() {
            let attributesData = new Map();
            let localData = new Map();
            const params = {
                accountNumber: this.$store.getters[GET_DATA]('accountNumber'),
                profileId: this.$store.getters[GET_DATA]('profileId'),
                emailAddress: this.$store.getters[GET_DATA]('emailAddress') ? this.$store.getters[GET_DATA]('emailAddress') : this.$store.getters[GET_DATA]('emailaddress'),
            };
            try {
                const autopayResult = await axios.post(constants.AutopayOrchestratorUrl, params);
                this.iframeSession = autopayResult.data.epwfSession;
                this.autopayIframeUrl = autopayResult.data.epwfSession.sessionURL;
                if(autopayResult.data.account && autopayResult.data.account.billerEnsemble !== undefined){
                    attributesData.set('isBillerEnsemble', autopayResult.data.account.billerEnsemble );
                    this.$store.dispatch(SET_DATA, {attributes:attributesData});
                } else {
                    attributesData.set('isBillerEnsemble', false );
                    this.$store.dispatch(SET_DATA, {attributes:attributesData});
                }
                this.brimStatus = 'ERROR';
                localData.set('showLoading', false);
                this.$store.dispatch(local_setdata, {attributes:localData});
                this.showLoader = false;
            } catch (error) {
                console.log(error);
                this.brimStatus = 'ERROR';
                localData.set('showLoading', false);
                this.$store.dispatch(local_setdata, {attributes:localData});
                this.showLoader = false;
            }
            localData.set('showLoading', false);
            this.$store.dispatch(local_setdata, {attributes:localData});
            this.showLoader = false;
        },
        onAutopayIframeResolutionchange() {
            if( this.autopayIframechange == 1 ){
                this.autopayiframeHeight = 900;
            }else{
                this.autopayIframechange++;
            }
        },
        pageRefresh() {
            this.modalClose();
            //this.customerDetails = JSON.parse(this.$store.getters[local_getdata]('customerDetails'));
            if(this.customerData !== null || this.customerData !== '') this.customerDetails = JSON.parse(this.customerData);
            this.autopayEnrollStatus = this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].autopayEnrollmentInfo.autopayStatus;
        },
        modalClose() {
            this.autopayModal.hide();
            this.hcdeReady = false;
            this.hcdeReadyWallet = false;
        },
        ClosePopup() {
          this.popupModal.hide();
        },
        submitCardAutopay (token) {
            this.autopaySubmitRequest = {
                    customerDetails: {
                        addressDetails: {
                            streetAddress1: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.addressDetails[0].billingAddress.streetAddress1,
                            streetAddress2: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.addressDetails[0].billingAddress.streetAddress2,
                            city: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.addressDetails[0].billingAddress.city,
                            stateProvince: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.addressDetails[0].billingAddress.stateProvince,
                            postalCd: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.addressDetails[0].billingAddress.postalCd,
                            addressType: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.addressDetails[0].billingAddress.addressType,
                            countryCd: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.addressDetails[0].billingAddress.countryCd
                        },
                        billingAccountNumber: this.$store.getters[GET_DATA]('accountNumber'),
                        billingApplicationCd: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.billingApplicationCd,
                        customerEmail: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.email ? this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.email : this.$store.getters[GET_DATA]('emailAddress'),
                        customerFirstName: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.customerFirstName,
                        customerLastName: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.customerLastName,
                        customerType: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.customerType,
                        paperLessBillingInd: 'N'
                    },
                    paymentMethodDetails: {
                        cardDetails: { cardToken: token }
                    },
                    enrollmentType: 'Enroll',
                    userId: 'dssp',
                    paymentMethodCd: 'CARD',
                    currencyCd: 'USD',
                    ivrInteractionId: null
                };
            this.submitAutopay();
        },
        submitBankAutopay(token) {
            this.autopaySubmitRequest = {
                    customerDetails: {
                        addressDetails: {
                            streetAddress1: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.addressDetails[0].billingAddress.streetAddress1,
                            streetAddress2: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.addressDetails[0].billingAddress.streetAddress2,
                            city: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.addressDetails[0].billingAddress.city,
                            stateProvince: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.addressDetails[0].billingAddress.stateProvince,
                            postalCd: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.addressDetails[0].billingAddress.postalCd,
                            addressType: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.addressDetails[0].billingAddress.addressType,
                            countryCd: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.addressDetails[0].billingAddress.countryCd
                        },
                        billingAccountNumber: this.$store.getters[GET_DATA]('accountNumber'),
                        billingApplicationCd: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.billingApplicationCd,
                        customerEmail: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.email ? this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.email : this.$store.getters[GET_DATA]('emailAddress') ,
                        customerFirstName: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.customerFirstName,
                        customerLastName: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.customerLastName,
                        customerType: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.customerType,
                        paperLessBillingInd: 'N'
                    },
                    paymentMethodDetails: {
                        bankAccountDetails: { achToken: token }
                    },
                    enrollmentType: 'Enroll',
                    userId: 'dssp',
                    paymentMethodCd: 'ACH',
                    currencyCd: 'USD',
                    ivrInteractionId: null
                };
            this.submitAutopay();
        },
        async createBankPopupModal(token) {
            this.walletInstrumentId = token;
            let bankAccountNum = document.getElementById('bankAccountNumber');
            this.enteredAccountNumber = bankAccountNum.value;
            let bankRoutingNum = document.getElementById('bankRoutingNumber');
            this.enteredRoutingNumber = bankRoutingNum.value;
            let bankAccType = document.getElementById('bankAccountType');
            this.enteredBankType = bankAccType.value;
            this.popupModal.show();
            this.selectedOption = 'Bank';
        },
        async createCardPopupModal(token) {
            this.myTokenVar = token;
            let CardNumber = document.getElementById('hcdeCardNumber');
            this.enteredCardNumber = CardNumber.value;
            let hcdeCardExpMonth = document.getElementById('hcdeCardExpiryMonth');
            this.hcdeCardExpiryMonth = hcdeCardExpMonth.value;
            let hcdeCardExpYear = document.getElementById('hcdeCardExpiryYear');
            this.hcdeCardExpiryYear = hcdeCardExpYear.value;
            let hcdeZip = document.getElementById('hcdeZipCode');
            this.hcdeZipCode = hcdeZip.value;
            this.popupModal.show();
            this.selectedOption = 'Card';
        },
        async submitAutopay() {
            let localData = new Map();
            localData.set('showLoading', true);
            this.$store.dispatch(local_setdata, {attributes:localData});
            this.showLoader = true;
            if (this.customerDetails != null) {
                try {
                    const res = await axios.post(constants.AutopayEnrollAndDeEnroll, this.autopaySubmitRequest);
                    if (res.data.transactionStatus == 'S') {
                        setTimeout(this.disableSpinner, 5000); // 2000 milliseconds (2 seconds)
                        let attributesData = new Map();
                        attributesData.set("BrimEnroll", 'Enroll');
                        attributesData.set('walletRefNumberBrim', res.data.walletReferenceVal);
                        this.$store.dispatch(SET_DATA, { attributes: attributesData });
                        this.modalClose();
                        this.ClosePopup();
                        this.$router.push({
                            name: "SuccessAP",
                            params: {},
                        });
                    } else {
                        setTimeout(this.disableSpinner, 5000); // 2000 milliseconds (2 seconds)
                        this.modalClose();
                        this.ClosePopup();
                        this.$router.push({
                            name: "FailureAP",
                            params: {},
                        });
                    }
                } catch (err) {
                    console.log(err);
                    setTimeout(this.disableSpinner, 5000); // 2000 milliseconds (2 seconds)
                    this.modalClose();
                    this.ClosePopup();
                    this.$router.push({
                        name: "FailureAP",
                        params: {},
                    });
                }
            } else {
                setTimeout(this.disableSpinner, 5000); // 2000 milliseconds (2 seconds)
                this.modalClose();
                this.ClosePopup();
                this.$router.push({
                    name: "FailureAP",
                    params: {},
                });
            }
        },
        disableSpinner(){
            let localData = new Map();
            localData.set('showLoading', false);
            this.$store.dispatch(local_setdata, {attributes:localData});
            this.showLoader = false;
        }
    }
}
</script>
<style>
#confirmationModal .modal-body {
  padding-top: 0 !important;
  text-align: left;
  padding-left: 0.5rem;
}
#confirmationModal .no-border {
  text-align: left;
}
.text-decor {
    color: #14161a;
    font-weight: 500;
    margin-top: 1rem;
}
.text-decor-left {
    color: #14161a;
    font-weight: 500;
    margin-top: 1rem;
    font-size: 24px;
    text-align: left;
}
.autopay-iframe {
    margin: 1.5rem 0 1rem 0;
    padding: 0px;
    overflow: hidden
}
.padding0 {
    padding: 0 !important;
}
.autopay-enroll-status {
    font-family: MaisonNeue, sans-serif;
    font-size: 14px;
    color: #000000;
    font-weight: bold;
    margin: 2rem 0 2rem 0;
}
.setup-button {
    border: 1px solid #0047bb;
    margin: 1rem 2rem 2rem 1rem;
    border-radius: 4px;
    color: #0047bb;
}
.setup-button:hover {
    border: 1px solid #0047bb;
    margin: 1rem 2rem 2rem 1rem;
    border-radius: 4px;
    color: #0047bb;
}
.large {
  text-align: left;
  --bs-modal-width: 760px;
}
.content-size {
  padding: 1rem;
}
.align-center {
  padding: 1rem;
  display: flex;
  justify-content: center;
}
.flex-component {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  font-size: 16px;
  text-align: left;
  gap: .5rem;
}
.flex-component-button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.content-spacing {
  padding: 1.75rem;
}
.alert-content {
  padding-top: 0.75rem;
}
.flex-entry {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-size: 16px;
  text-align: end;
  margin-left: 1rem;
  gap: 0.75rem;
}
.flex-entry-button {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-size: 16px;
  text-align: end;
  margin-left: 0rem;
  gap: 0.75rem;
}
.flex-label {
  width: 10rem;
}
.flex-span {
  display: flex;
  flex-direction: row;
}
.Submit-Button{
  background-color: #0047bb;
  border-radius: 4px;
  font-weight: 400;
  height: 36px;
  width: 90px;
  padding: 0;
  text-transform: none;
  margin-left: 5px;
  display: inline-block;
  text-decoration: none;
  color: #fff;
  border: none;
}
.cancel-button {
  border: none;
  color: #0047bb;
  text-decoration: underline;
  background: none;
  padding: 0rem;
}
.hcde-comp {
    padding: 0 10px;
}
@media(max-width:767px) {
  .flex-component-button {
    gap: 3rem;
  }
  .large{
    --bs-modal-width: 600px !important;
  }
  .flex-entry{
    margin-left: -2rem;
  }
  .p {
    font-size: 14px;
  }
  .align-center {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
  .flex-span {
    word-break: break-all;
  }
}
</style>
