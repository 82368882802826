<template>
    <div class="autopayContainer">
        <div class="row">
            <h1 class="text-decor">Manage AutoPay</h1>
            <div v-if="this.paymentMethodType == 'CARD'" class=" col-sm-08 col-xs-12 text-start content-margin" style="color: #000000;">
                <h3 class="text-decor-left mb-2 p-0">Your AutoPay details</h3>
                <p class='m-0'>Your saved payment information</p>
                <p class='m-0'>Name on Card: {{this.fullNameOnCard}}</p>
                <p class='m-0'>Card Number: {{this.maskedAccountNumber}}</p>
                <p class='m-0'>Expiration Date: {{this.cardExpDate}}</p>
            </div>
            <div v-else-if="this.paymentMethodType == 'ACH'" class=" col-sm-08 col-xs-12 text-start content-margin" style="color: #000000;">
                <h3 class="text-decor-left mb-2 p-0">Your AutoPay details</h3>
                <p class='m-0'>Your saved payment information</p>
                <p class='m-0'>Name on Bank Account: {{this.fullNameOnCard}}</p>
                <p class='m-0'>Bank Account Number: {{this.maskedAccountNumber}}</p>
            </div>
            <div v-else-if="this.paymentMethodType == 'PAYPAL'" class=" col-sm-08 col-xs-12 text-start content-margin" style="color: #000000;">
                <h3 class="text-decor-left mb-2 p-0">Your AutoPay details</h3>
                <p class='m-0'>You are enrolled on Paypal.</p>
            </div>
            <div v-if="isDataAvailable" class="col-sm-6 col-xs-12 content-margin">
                <div class="bill-details-box">
                    <div class="bill-header text-start">
                        <img class="bill-icon" src="../../images/bill_icon.png" alt="bill-icon"/>
                        <h2>YOUR BILL AT-A-GLANCE</h2>
                    </div>
                    <hr />
                    <div v-if="billDate !== null && billDate !== ''">
                        <div class="row bill-header">
                            <div class="col-6 text-start bill-detail-font">BILL DATE</div>
                            <div class="col-6 text-end bill-detail-font">{{ billDate }}</div>
                        </div>
                    </div>
                    <div v-if="billAmount !== null && billAmount !== '' && billAmount !== false">
                        <div class="row bill-header">
                            <div class="col-6 text-start bill-detail-font">BILL AMOUNT</div>
                            <div class="col-6 text-end bill-detail-font">${{ billAmount }}</div>
                        </div>
                    </div>
                    <div v-if="paymentDueDate !== null && paymentDueDate !== ''">
                        <div class="row bill-header">
                            <div class="col-6 text-start bill-detail-font">PAYMENT DUE DATE</div>
                            <div class="col-6 text-end bill-detail-font">{{ paymentDueDate }}</div>
                        </div>
                    </div>
                    <div v-if="currentAmountDue !== null && currentAmountDue !== '' && currentAmountDue !== false">
                        <div class="row bill-header">
                            <div class="col-7 text-start bill-detail-font">CURRENT AMOUNT DUE</div>
                            <div class="col-5 text-end bill-detail-font">${{ currentAmountDue }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="!isDataAvailable" class="col-sm-4 col-xs-12 noData-content-margin">
                <img class="no-data-image" src="../../images/MicrosoftTeams-image.png" alt="NoData"/>
            </div>
        </div>
        <div class="row mb-4 autopay-block">
            <button id="prepaidUpdateBtnCard" type="button" class="btn btn-primary btn-lg text-center" @click="showCardBank()">Update payment method</button>
        </div>
        <div id="myModal" class="modal fade manage-wallet-bank-card" role="dialog">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header no-border">
                        <h5 v-if="this.cardOptionTitle" class="modal-title">Edit card info</h5>
                        <h5 v-if="this.bankOptionTitle" class="modal-title">Edit Bank info</h5>
                        <h5 v-if="this.payPalOptionTitle" class="modal-title">Edit PayPal info</h5>
                        <button type="button" class="btn" @click="modalClose()" aria-label="Close">
                            <img src="../../images/svg/icon-navigation-close_24px.svg" alt="outline-info"/>
                        </button>
                    </div>
                    <div class="bank-img-section" v-if="this.cardImg">
                        <img class="visa-img" src="../../images/visa.svg" alt="visa">
                        <img class="mastercard-img" src="../../images/mastercard.svg" alt="mastercard">
                        <img class="discover-img" src="../../images/discover.svg" alt="discover">
                    </div>
                    <!-- component -->
                    <div class="hcde-comp">
                        <div id="termsModal">
                            <div class="modal-content">
                                <div>
                                    <h6>Terms and Conditions</h6>
                                    <p id="closeButton" class="close">&times;</p>
                                </div>
                            <p>By accepting this service, you agree to be enrolled in automatic monthly payments and paperless billing and billed monthly by credit or debit card in advance until cancelled. We will charge this account at the beginning of your billing cycle, each billing cycle is one month, and service automatically renews monthly until cancelled. When cancelling service on any day other than the last day of your billing cycle, all charges, fees, and taxes will apply in full and payments for unused service will not be refunded or credited. If you have leased equipment, you must return all of that equipment within 30 days of any cancellation or termination of services in the manner that CenturyLink instructs you. If you fail to return any equipment in a timely manner, CenturyLink may assess a non-return fee of up to $200 per piece of equipment. For more information on your fees and taxes is found at
                            <a href="
                            http://www.centurylink.com/feesandtaxes" target="_blank">http://www.centurylink.com/feesandtaxes</a>.</p>
                            </div>
                        </div>
                        <hcde-tokenization v-if="this.hcdeReady" :env="this.env" clientId="MYA_PAY" :sessionId="this.sessionId" callbackFunction="showCardPopup" callbackField="myTokenVar" statusFunction="tokenStatus" statusField="statusVar" heartBeatFrequency="60" srcSysTransId="307902021108">Loading...</hcde-tokenization>
                        <hcde-wallet v-if="this.hcdeReadyWallet" :env="this.env" clientId="MYA_PAY" :sessionId="this.sessionId" callbackFunction="showBankPopup" callbackRefNumField="walletRefNum"  callbackInstrumentField="walletInstrumentId" statusFunction="walletStatus" statusField="statusVar" heartBeatFrequency="60" srcSysTransId="1234567890" >Loading...</hcde-wallet>
                        <hcde-paypal v-if="this.hcdeReadyPayPal" :env="this.env" clientId="MYA_PAY" :sessionId="this.sessionId" callbackFunction="showPaypalPopup" callbackField="myPaypalVar" statusFunction="paypalStatus" statusField="statusVar" heartBeatFrequency="60" srcSysTransId="307902021108" >Loading...</hcde-paypal> 
                    </div>
                </div>
            </div>
        </div>
      <div id="confirmationModal" class="modal large" role="dialog">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content content-size">
            <div v-if="this.selectedOption === 'Paypal'" class="modal-header no-border">
              <p class="modal-title-1">Please confirm your AutoPay request:</p>
            </div>
            <div class="modal-body">
              <div v-if="this.selectedOption === 'Paypal'" class="hcde-comp">
                <p>You are being enrolled with PayPal.</p>
              </div>
              <div v-else class="hcde-comp">
                <p>The full amount of your bill will be automatically deducted using this payment method.</p>
              </div>
              <div class="align-center">
                <div class="flex-component">
                  <div v-if="this.selectedOption === 'Card'" class="flex-entry">
                    <p class="flex-label">Card Number:</p><p class="flex-span"> {{ this.enteredCardNumber }}</p>
                  </div>
                  <div v-if="this.selectedOption === 'Card'" class="flex-entry">
                    <p class="flex-label">Process as:</p><p class="flex-span"> Credit/Debit card</p>
                  </div>
                  <div v-if="this.selectedOption === 'Card'" class="flex-entry">
                    <p class="flex-label">Expiration:</p><p class="flex-span"> {{ this.hcdeCardExpiryMonth }} / {{ this.hcdeCardExpiryYear }}</p>
                  </div>
                  <div v-if="this.selectedOption === 'Card'" class="flex-entry">
                    <p class="flex-label">Billing Zip:</p><p class="flex-span"> {{ this.hcdeZipCode }}</p>
                  </div>
                  <div v-if="this.selectedOption === 'Card'" class="flex-entry">
                    <p class="flex-label">Currency:</p><p class="flex-span"> USD </p>
                  </div>

                  <div v-if="this.selectedOption === 'Bank'" class="flex-entry">
                    <p class="flex-label">Routing Number:</p><p class="flex-span"> {{ this.enteredRoutingNumber }}</p>
                  </div>
                  <div v-if="this.selectedOption === 'Bank'" class="flex-entry">
                    <p class="flex-label">Account Number:</p><p class="flex-span"> {{ this.enteredAccountNumber }}</p>
                  </div>
                  <div v-if="this.selectedOption === 'Bank'" class="flex-entry">
                    <p class="flex-label">Bank Account Type:</p><p class="flex-span"> {{ this.enteredBankType }} Account</p>
                  </div>
                  <div v-if="this.selectedOption === 'Bank'" class="flex-entry">
                    <p class="flex-label">Currency:</p><p class="flex-span"> USD </p>
                  </div>
                </div>
              </div>
              <div class="hcde-comp">
                <p class="alert-content">Does this information look correct or do you need to
                  <button type="button" class="cancel-button" @click="ClosePopup()">Make Changes</button>?</p>
              </div>
              <div class="flex-component-button content-spacing">
                <div class="flex-entry-button">
                  <button class="Submit-Button" @click="ClosePopup()">Cancel</button>
                </div>
                <div class="flex-entry-button">
                  <div v-if="this.selectedOption === 'Card'" class="flex-entry">
                    <button class="Submit-Button" @click="updateCardAutopay(this.myTokenVar)">Save</button>
                  </div>
                  <div v-if="this.selectedOption === 'Bank'" class="flex-entry">
                    <button class="Submit-Button" @click="updateBankAutopay(this.walletInstrumentId)">Save</button>
                  </div>
                  <div v-if="this.selectedOption === 'Paypal'" class="flex-entry">
                    <button class="Submit-Button" @click="updatePaypalAutopay(this.myPaypalVar)">Save</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        <div id="editcardbank" class="modal fade update-bank-card" role="dialog">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header no-border">
                        <h5 class="modal-title">Update payment method</h5>
                        <button type="button" class="btn" @click="editModalClose()" aria-label="Close">
                            <img src="../../images/svg/icon-navigation-close_24px.svg" alt="outline-info"/>
                        </button>
                    </div>
                    <div class="bank-card-section">
                        <div v-if='this.isAchAllowed' class="edit-bank" @click="showBankDetails()">
                            <div class="edit-bank-icon-circle">
                                <img alt="payment method icon" class="bank-method-icon" src="../../images/institute.png">
                            </div>
                            <div class="bank-accnt">
                                <div class="content-style">Bank Account</div>
                            </div>
                        </div>  
                        <div v-if='this.isCardAllowed' class="update-card"  @click="showCardDetails()">
                            <div class="edit-card-circle">
                                <img alt="payment method icon" class="card-method-icon" src="../../images/Card.png">
                            </div>
                            <div class="bank-accnt">
                                <div class="content-style">Credit/Debit Card</div>
                            </div>					
                        </div>
                        <div v-if='this.isPayPalAllowed' class="update-paypal"  @click="showPayPalDetails()">
                            <div class="edit-paypal-circle">
                                <img alt="payment method icon" class="paypal-method-icon" src="../../images/paypal.png">
                            </div>
                            <div class="bank-accnt">
                                <div class="content-style">PayPal</div>
                            </div>					
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import links from "../../js/links";
import { GET_DATA, SET_DATA, ATTRIBUTE_RESET } from "../../store/sessioncontants";
import { SET_DATA as local_setdata } from "../../store/storecontants";
import { Modal } from "bootstrap/dist/js/bootstrap.bundle";
import axios from 'axios';
import constants from '../../js/constants';
import router from '../../router'
import hcdeStatus from "../../js/hcdestatus";

export default {
    name: 'AutopayPrepaid',
    props: ['customerData'],
    data() {
        return {
            billDate: null,
            billAmount: null,
            paymentDueDate: null,
            currentAmountDue: null,
            LearnMoreAutoPayURL: links.LearnMoreAutoPayURL,
            isDataAvailable: false,
            customerDetails: null,
            firstNameOnCard: null,
            lastNameOnCard: null,
            fullNameOnCard: null,
            maskedAccountNumber: null,
            cardExpDate: null,
            cardImg : false,
            hcdeReady : false,
            hcdeReadyWallet: false,
            hcdeReadyPayPal: false,
            WalletJSEncrypt: links.WalletJSEncrypt,
            hcdeWalletServiceApi: links.hcdeWalletServiceApi,
            hcdeTokenServiceApi: links.hcdeTokenServiceApi,
            hcdePaypalServiceApi: links.hcdePaypalServiceApi,
            hcdeTokenComponent: links.hcdeTokenComponent,
            hcdeWalletComponent: links.hcdeWalletComponent,
            hcdePaypalComponent: links.hcdePaypalComponent,
            jsPayPalMain: links.jsPayPalMain,
            zipCode: null,
            paymentMethodDetails: null,
            cardType: null,
            bankOptionTitle: false,
            cardOptionTitle: false,
            payPalOptionTitle: false,
            transactionStatusbank: '',
            transactionStatuscard: '',
            paymentMethodType: null,
            isCardAllowed: true,
            isAchAllowed: true,
            isPayPalAllowed: true,
            convenienceFeeAmtAch: null,
            convenienceFeeAmtCard: null,
            selectedOption: null,
            paymentAmountEntered: null,
            enteredAccountNumber: null,
            enteredCardNumber: null,
            enteredBankType: null,
            enteredRoutingNumber: null,
            hcdeCardExpiryMonth: null,
            hcdeCardExpiryYear: null,
            hcdeZipCode: null,
            myTokenVar: null,
            walletInstrumentId: null,
            myPaypalVar: null,
            isBrimOn: false
        }
    },
    mounted() {
        this.isBrimOn = process.env.VUE_APP_BRIM;
        if(this.isBrimOn == 'true'){
            this.billDate = this.$store.getters[GET_DATA]('currentInvoiceDate') ? this.getHumanReadbledt(this.$store.getters[GET_DATA]('currentInvoiceDate')) : null;
            this.paymentDueDate = this.$store.getters[GET_DATA]('currentInvoiceDueDate') ? this.getHumanReadbledt(this.$store.getters[GET_DATA]('currentInvoiceDueDate')) : null;
            this.billAmount = this.$store.getters[GET_DATA]('currentInvoiceAmount') ? this.twoDecimal(this.$store.getters[GET_DATA]('currentInvoiceAmount')) : null;
            this.currentAmountDue = this.$store.getters[GET_DATA]('totalDueAmount') ? this.twoDecimal(this.$store.getters[GET_DATA]('totalDueAmount')) : null;
        } else {
            this.billDate = this.$store.getters[GET_DATA]('billDate') ? this.getHumanReadbledt(this.$store.getters[GET_DATA]('billDate')) : null;
            this.paymentDueDate = this.$store.getters[GET_DATA]('paymentDueDate') ? this.getHumanReadbledt(this.$store.getters[GET_DATA]('paymentDueDate')) : null;
            this.billAmount = this.$store.getters[GET_DATA]('billAmount') ? this.twoDecimal(this.$store.getters[GET_DATA]('billAmount')) : null;
            this.currentAmountDue = this.$store.getters[GET_DATA]('currentAmountDue') ? this.twoDecimal(this.$store.getters[GET_DATA]('currentAmountDue')) : null;
        }

        this.$store.dispatch(ATTRIBUTE_RESET, { attributes: ['enrollmentType' , 'BrimEnroll', 'modifyenrollment'] });
        localStorage.clear();
        let localData = new Map();
        localData.set('showLoading', true);
        this.$store.dispatch(local_setdata, {attributes:localData});
        this.getAutopayBrimDetails();
        this.env = process.env.VUE_APP_env;
        if(this.customerData !== null || this.customerData !== '') {
            this.customerDetails = JSON.parse(this.customerData);
        }
        if (this.customerDetails) {
            this.firstNameOnCard = this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.customerFirstName
            this.lastNameOnCard = this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.customerLastName
            this.fullNameOnCard = this.firstNameOnCard + ' ' + this.lastNameOnCard;
            this.maskedAccountNumber = this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].autopayEnrollmentInfo.maskedAccountNumber;
            this.cardExpDate = this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].autopayEnrollmentInfo.cardExpirationDate;
            this.zipCode = this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.addressDetails[0].billingAddress.postalCd;
            this.paymentMethodType = this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].autopayEnrollmentInfo.autopayPaymentMethodCd;
            let attributesData = new Map();
            attributesData.set("walletRefNumberBrim", this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].autopayEnrollmentInfo.autopayWalletId);
            this.$store.dispatch(SET_DATA, { attributes: attributesData });
        }
        const achFlag = JSON.stringify(this.$store.getters[GET_DATA]('isAchAllowed'));
        const cardFlag = JSON.stringify(this.$store.getters[GET_DATA]('isCardAllowed'));
        this.isAchAllowed = (achFlag === 'true');
        this.isCardAllowed = (cardFlag === 'true');
        if ((this.billDate === null || this.billDate === '') && (this.billAmount === null || this.billAmount === '' || this.billAmount === false) && (this.paymentDueDate === null || this.paymentDueDate === '') && (this.currentAmountDue === null || this.currentAmountDue === '' || this.currentAmountDue === false)) {
            this.isDataAvailable = false;
        } else {
            this.isDataAvailable = true
        }
        this.manageModal = new Modal(document.getElementById('myModal'),{
            backdrop: 'static',
            keyboard: false
        });
        this.popupModal = new Modal(document.getElementById('confirmationModal'),{
          backdrop: 'static',
          keyboard: false
        });
        this.editCardBankModel = new Modal(document.getElementById('editcardbank'),{
            backdrop: 'static',
            keyboard: false
        });
        // eslint-disable-next-line
        const self = this;
        window.updateCardDetails = function (){
            self.updateCardAutopay(window.myTokenVar);
        };
        // eslint-disable-next-line
        window.updateBankDetails = function(){
            self.updateBankAutopay(window.walletInstrumentId);
        };
        // eslint-disable-next-line
        window.updatePaypalDetails = function(){
            self.updatePaypalAutopay(window.myPaypalVar);
        };
        // eslint-disable-next-line
        window.tokenStatus = function(){
            hcdeStatus.showTokenStatus(window.statusVar);
            self.checkStatus(window.statusVar);
        };
        // eslint-disable-next-line
        window.walletStatus = function(){
            hcdeStatus.showWalletStatus(window.statusVar);
            self.checkStatus(window.statusVar);
        };
        // eslint-disable-next-line
        window.paypalStatus = function(){
            hcdeStatus.showPaypalStatus(window.statusVar);
            self.checkStatus(window.statusVar);
        };
        window.showBankPopup = function(){
          self.createBankPopupModal(window.walletInstrumentId);
        };
        window.showCardPopup = function(){
          self.createCardPopupModal(window.myTokenVar);
        };
        window.showPaypalPopup = function(){
          self.createPaypalPopupModal(window.myPaypalVar);
        };
    },
    updated() {
    this.isBrimOn = process.env.VUE_APP_BRIM;
    if(this.isBrimOn == 'true'){
        this.createHcdeTagListener()
        }
    },
    beforeUnmount() {
        // It's a good practice to clean up by removing the function when the component is destroyed
        delete window.updateBankDetails;
        delete window.updateCardDetails;
        delete window.updatePaypalDetails;
        delete window.tokenStatus;
        delete window.walletStatus;
        delete window.paypalStatus;
    },
    methods: {
        showCardBank() {
            this.editCardBankModel.show();
        },
        checkStatus(status) {
            if(status.includes("EXPIRED")){
                this.modalClose()
                this.$router.push({
                    name: "FailureAP",
                    params: {showTimeout: 'timeOut'},
                });
            }
        },
        async getAutopayBrimDetails() {
            let localData = new Map();
            localData.set('showLoading', true);
            this.$store.dispatch(local_setdata, {attributes:localData});
            try {
                let params = {
                    accountNumber: this.$store.getters[GET_DATA]('accountNumber'),
                    profileId: this.$store.getters[GET_DATA]('profileId'),
                    emailAddress: this.$store.getters[GET_DATA]('emailAddress')
                }
                const res = await axios.post(constants.AutopayOrchestratorUrl, params);
                if(res.data.epwfSession.sessionId) {     
                    this.sessionId = res.data.epwfSession.sessionId;
                    localData.set('showLoading', false);
                    this.$store.dispatch(local_setdata, {attributes:localData});
                } else {
                    this.brimStatus = 'ERROR';
                    localData.set('showLoading', false);
                    this.$store.dispatch(local_setdata, {attributes:localData});
                }
            } catch (err) {
                console.log(err);
                this.brimStatus = 'ERROR';
                localData.set('showLoading', false);
                this.$store.dispatch(local_setdata, {attributes:localData});
            }
        },
        getHumanReadbledt(timeStamp) {
            if (timeStamp == 'NA') {
                return null;
            }
            if (timeStamp.includes('-')) {
                timeStamp = timeStamp.replaceAll('-', '/');
            }
            const monthArr = [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
            ];
            const date = new Date(timeStamp);
            const year = date.getFullYear();
            const month = monthArr[date.getMonth()];
            const newDate = date.getDate();
            const readableDate = month + " " + newDate + ", " + year;
            return readableDate;
        },
        twoDecimal(twodecimal) {
            if (twodecimal == 'NA') {
                return null;
            }
            let num = parseFloat(twodecimal)
            let with2Decimals = num.toFixed(2);
            return with2Decimals;
        },
        removeJSfile(oldfilename) {
            let targetelement = 'script';
            let targetattr = 'src';
            let allsuspects = document.getElementsByTagName(targetelement);
            for (let i = allsuspects.length; i >= 0; i--) {
                //search backwards within nodelist for matching elements to remove
                if (allsuspects[i] && allsuspects[i].getAttribute(targetattr) != null && allsuspects[i].getAttribute(targetattr).indexOf(oldfilename) != -1) {
                    allsuspects[i].parentNode.removeChild(allsuspects[i]);
                }
            }
        },
        async createHcdeTagListener() {
            const scripts = [
                this.WalletJSEncrypt,
                this.hcdeTokenServiceApi,
                this.hcdeWalletServiceApi,
                this.hcdePaypalServiceApi
            ];
            const integrity = {
                [this.WalletJSEncrypt]: constants.jsencryptHash,
                [this.hcdeTokenServiceApi]: constants.hcdeTokenServiceApiHash,
                [this.hcdeWalletServiceApi]: constants.hcdeWalletServiceApiHash,
                [this.hcdePaypalServiceApi]: constants.hcdePaypalServiceApiHash
            };
            scripts.forEach(script => {
                let tag = document.head.querySelector(`[src="${ script }"`);
                if (!tag) {
                    tag = document.createElement("script");
                    tag.setAttribute("src", script);
                    tag.setAttribute("type", 'text/javascript');
                    tag.setAttribute("integrity", integrity[script]);
                    tag.setAttribute("crossorigin",'anonymous');
                    document.head.appendChild(tag); 
                }
            });
        },
        modalClose() {
            this.manageModal.hide();
            this.hcdeReady = false;
            this.hcdeReadyWallet = false;
            this.hcdeReadyPayPal = false;
        },
        ClosePopup() {
        this.popupModal.hide();
        },
        editModalClose(){
            this.editCardBankModel.hide();
        },
        async removeAutopay() {
            let localData = new Map();
            localData.set('showLoading', true);
            let attributesData = new Map();
            if (this.paymentMethodType == 'CARD') {
                this.paymentMethodDetails = {
                    walletDetails: { walletReferenceNumber: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].autopayEnrollmentInfo.autopayWalletId },
                    cardDetails: { maskedAccountNumber: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].autopayEnrollmentInfo.maskedAccountNumber }
                };
            } else if (this.paymentMethodType == 'ACH') {
                this.paymentMethodDetails = {
                    walletDetails: { walletReferenceNumber: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].autopayEnrollmentInfo.autopayWalletId },
                    bankAccountDetails: {
                        bankRoutingNumber: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].autopayEnrollmentInfo.bankRoutingNumber,
                        bankAccountType: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].autopayEnrollmentInfo.bankAccountType,
                        bankAccountNumber: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].autopayEnrollmentInfo.maskedAccountNumber
                    }
                };
            }
            const params = {
                    customerDetails: {
                        addressDetails: {
                            streetAddress1: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.addressDetails[0].billingAddress.streetAddress1,
                            city: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.addressDetails[0].billingAddress.city,
                            addressType: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.addressDetails[0].billingAddress.addressType,
                            countryCd: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.addressDetails[0].billingAddress.countryCd,
                            postalCd: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.addressDetails[0].billingAddress.postalCd
                        },
                        billingAccountNumber: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.billingAccountNumber,
                        billingApplicationCd: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.billingApplicationCd,
                        customerFirstName: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.customerFirstName,
                        customerType: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.customerType,
                        customerEmail: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.email ? this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.email : this.$store.getters[GET_DATA]('emailAddress')
                    },
                    paymentMethodDetails: this.paymentMethodDetails,
                    enrollmentType: 'DeEnroll',
                    userId: 'DSSP',
                    paymentMethodCd: this.paymentMethodType,
                    currencyCd: 'USD'
            };
            try {
                const result = await axios.post(constants.AutopayEnrollAndDeEnroll, params);
                if (result.data.transactionStatus == 'S') {
                    localData.set('showLoading', false);
                    this.$store.dispatch(local_setdata, {attributes:localData});
                    attributesData.set("enrollmentType", 'DeEnroll');
                    this.$store.dispatch(SET_DATA, { attributes: attributesData });
                    this.$router.push({
                        name: "SuccessAP",
                        params: {},
                    });
                } else {
                    this.$router.push({
                        name: "FailureAP",
                        params: {},
                    });
                    localData.set('showLoading', false);
                    this.$store.dispatch(local_setdata, {attributes:localData});
                }
            } catch (err) {
                console.log(err);
                this.$router.push({
                    name: "FailureAP",
                    params: {},
                });
            }
            localData.set('showLoading', false);
            this.$store.dispatch(local_setdata, {attributes:localData});
        },
        showBankDetails() {
            let localData = new Map();
            localData.set('showLoading', true);
            this.$store.dispatch(local_setdata, {attributes:localData});
            this.bankOptionTitle = true;
            this.cardOptionTitle = false;
            this.payPalOptionTitle = false;
            this.cardImg = false;
            this.editCardBankModel.hide();
            this.manageModal.show();
            this.bankOption();
            localData.set('showLoading', false);
            this.$store.dispatch(local_setdata, {attributes:localData});
        },
        showCardDetails() {
            let localData = new Map();
            localData.set('showLoading', true);
            this.$store.dispatch(local_setdata, {attributes:localData});
            this.bankOptionTitle = false;
            this.cardOptionTitle = true;
            this.payPalOptionTitle = false;
            this.cardImg = true;
            this.editCardBankModel.hide();
            this.manageModal.show();
            this.cardOption();
            localData.set('showLoading', false);
            this.$store.dispatch(local_setdata, {attributes:localData});
        },
        showPayPalDetails() {
            let localData = new Map();
            localData.set('showLoading', true);
            this.$store.dispatch(local_setdata, {attributes:localData});
            this.bankOptionTitle = false;
            this.cardOptionTitle = false;
            this.payPalOptionTitle = true;
            this.cardImg = false;
            this.editCardBankModel.hide();
            this.manageModal.show();
            this.payPalOption();
            localData.set('showLoading', false);
            this.$store.dispatch(local_setdata, {attributes:localData});
        },
        bankOption() {
            this.hcdeReady = false;
            this.hcdeReadyWallet = true;
            this.hcdeReadyPayPal = false;
            const bankscripts = [
                this.hcdeWalletComponent				
            ];
            bankscripts.forEach(script => {
                let scriptFound = document.head.querySelector(`[src="${ script }"`) ? true : false;
                if (scriptFound) {
                    this.removeJSfile(script);
                }
                let tag = document.head.querySelector(`[src="${ script }"`);
                if (!tag) {
                    tag = document.createElement("script");
                    tag.setAttribute("src", script);
                    tag.setAttribute("type", 'text/javascript');
                    tag.setAttribute("integrity", constants.hcdeWalletComponentHash);
                    tag.setAttribute("crossorigin",'anonymous');
                    document.head.appendChild(tag); 
                    tag.addEventListener('load', function () {
                        // eslint-disable-next-line no-undef
                        this.hcdeTagString = new HCDEWalletServiceComponent();

                        const digitsOnlyRegExp = /^[0-9]*$/;

                        let hcdeBankDetailCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(1)');
                        hcdeBankDetailCon.setAttribute('id', 'hcdeBankDetailContainer');
                        hcdeBankDetailCon.setAttribute('class', 'hcdeComponentInputContainer form-floating form-group col-12');

                        let hcdeRouteNumLabel = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(1) > label');
                        hcdeRouteNumLabel.innerText = "";

                        let hcdeRouteNumInput = document.getElementById('bankRoutingNumber');
                        hcdeRouteNumInput.setAttribute('class', 'hcdeComponentTextBox form-control');
                        hcdeRouteNumInput.setAttribute('placeholder', ' ');
                        hcdeRouteNumInput.setAttribute('maxlength', '9');
                        hcdeRouteNumInput.setAttribute('oninput', 'onChangeRouteNum()');
                        hcdeRouteNumInput.addEventListener("keypress", e => {
                            if (!digitsOnlyRegExp.test(e.key)) {
                                e.preventDefault();
                            }
                        });

                        let newRoutingNumberLabel = document.createElement('label');
                        newRoutingNumberLabel.setAttribute('class', 'hcdeComponentLabel d-inline-block text-start text-truncate form-label col-12');
                        newRoutingNumberLabel.setAttribute('id', 'hcdeRouteNumberLabel');
                        newRoutingNumberLabel.innerText = "Routing Number";
                        hcdeBankDetailCon.appendChild(newRoutingNumberLabel);

                        let hcdeAcctNumCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(2)');
                        hcdeAcctNumCon.setAttribute('id', 'hcdeAccountNumberContainer');
                        hcdeAcctNumCon.setAttribute('class', 'hcdeComponentInputContainer form-floating form-group col-12');

                        let hcdeAcctNumLabel = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(2) > label');
                        hcdeAcctNumLabel.innerText = "";

                        let hcdeAcctNumInput = document.getElementById('bankAccountNumber');
                        hcdeAcctNumInput.setAttribute('class', 'hcdeComponentTextBox form-control');
                        hcdeAcctNumInput.setAttribute('placeholder', '');
                        hcdeAcctNumInput.setAttribute('maxlength', '17');
                        hcdeAcctNumInput.setAttribute('oninput', 'onChangeAcctNum()');
                        hcdeAcctNumInput.addEventListener("keypress", e => {
                            if (!digitsOnlyRegExp.test(e.key)) {
                                e.preventDefault();
                            }
                        });

                        let newAcctNumberLabel = document.createElement('label');
                        newAcctNumberLabel.setAttribute('class', 'hcdeComponentLabel d-inline-block text-start text-truncate form-label col-12');
                        newAcctNumberLabel.setAttribute('id', 'hcdeAccountNumberLabel');
                        newAcctNumberLabel.innerText = "Account Number";
                        hcdeAcctNumCon.appendChild(newAcctNumberLabel);

                        let hcdeAcctTypeCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(3)');
                        hcdeAcctTypeCon.setAttribute('id', 'hcdeAccountTypeContainer');
                        hcdeAcctTypeCon.setAttribute('class', 'hcdeComponentInputContainer col-12');

                        let hcdeAcctTypeLabel = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(3) > label');
                        hcdeAcctTypeLabel.innerHTML = '';

                        let hcdeAcctTypeInput = document.getElementById('bankAccountType');
                        hcdeAcctTypeInput.options[0].innerText = 'Bank account type';
                        hcdeAcctTypeInput.setAttribute('onchange', 'onChangeAcctType()');

                        let hcdeBankButtonCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentButtonContainer');
                        hcdeBankButtonCon.setAttribute('id', 'hcdeSaveBankButtonContainer');

                        let hcdeSaveBankButton = document.getElementById('hcdeComponentSubmitButton');
                        hcdeSaveBankButton.setAttribute('value', 'Save');

                        // eslint-disable-next-line no-undef
                        checkBankInfo();
                    });
                }
                setTimeout(() => (
                    this.bankOptionTitle = true
                ), 135);   
            });
        },
        cardOption() {
            this.hcdeReady = true;
            this.hcdeReadyWallet = false;  
            this.hcdeReadyPayPal = false;
            const cardscripts = [
                this.hcdeTokenComponent				
            ];
            cardscripts.forEach(script=>{
                let scriptFound = document.head.querySelector(`[src="${ script }"`) ? true : false;
                if (scriptFound) {
                    this.removeJSfile(script);
                }
                let tag = document.head.querySelector(`[src="${ script }"`);
                if (!tag) {
                    tag = document.createElement("script");
                    tag.setAttribute("src", script);
                    tag.setAttribute("type", 'text/javascript');
                    tag.setAttribute("integrity", constants.hcdeTokenComponentHash);
                    tag.setAttribute("crossorigin",'anonymous');
                    document.head.appendChild(tag);
                    tag.addEventListener('load', function () {
                        // eslint-disable-next-line no-undef
                        this.hcdeTagString = new HCDETokenServiceComponent();
                        
                        const digitsOnlyRegExp = /^[0-9]*$/;

                        let hcdeCardNumCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(1)');
                        hcdeCardNumCon.setAttribute('id', 'hcdeCardNumberContainer');
                        hcdeCardNumCon.setAttribute('class', 'hcdeComponentInputContainer form-floating form-group col-12');

                        let hcdeCardNumLabel = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(1) > label');
                        hcdeCardNumLabel.innerText = "";

                        let hcdeCardNumInput = document.getElementById('hcdeCardNumber');
                        hcdeCardNumInput.setAttribute('class', 'hcdeComponentTextBox form-control');
                        hcdeCardNumInput.setAttribute('placeholder', ' ');
                        hcdeCardNumInput.setAttribute('maxlength', '16');
                        hcdeCardNumInput.setAttribute('oninput', 'onChangeCardNum()');
                        hcdeCardNumInput.addEventListener("keypress", e => {
                            if (!digitsOnlyRegExp.test(e.key)) {
                                e.preventDefault();
                            }
                        });

                        let newCardNumLabel = document.createElement('label');
                        newCardNumLabel.setAttribute('class', 'hcdeComponentLabel d-inline-block text-start text-truncate form-label col-12');
                        newCardNumLabel.setAttribute('id', 'hcdeCardNumberLabel');
                        newCardNumLabel.innerText = "Card Number";
                        hcdeCardNumCon.appendChild(newCardNumLabel);
                        
                        let hcdeExpMonthCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(2)');
                        hcdeExpMonthCon.setAttribute('id', 'hcdeExpMonthContainer');
                        hcdeExpMonthCon.setAttribute('class', 'hcdeComponentInputContainer col-6 pe-1');

                        let hcdeExpMonthLabel = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(2) > label');
                        hcdeExpMonthLabel.innerText = "";

                        let hcdeExpMonthInput = document.getElementById('hcdeCardExpiryMonth');
                        hcdeExpMonthInput.options[0].innerText = 'Expiration Month';
                        hcdeExpMonthInput.setAttribute('onchange', 'onChangeExpMth()');

                        let hcdeExpYearCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(3)');
                        hcdeExpYearCon.setAttribute('id', 'hcdeExpYearContainer');
                        hcdeExpYearCon.setAttribute('class', 'hcdeComponentInputContainer col-6 ps-1');

                        let hcdeExpYearLabel = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(3) > label');
                        hcdeExpYearLabel.innerText = "";
                        
                        let hcdeExpYearInput = document.getElementById('hcdeCardExpiryYear');
                        hcdeExpYearInput.options[0].innerText = 'Expiration Year';
                        hcdeExpYearInput.setAttribute('onchange', 'onChangeExpYr()');

                        let hcdeZipCodeCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(4)');
                        hcdeZipCodeCon.setAttribute('id', 'hcdeZipCodeContainer');
                        hcdeZipCodeCon.setAttribute('class', 'hcdeComponentInputContainer form-floating form-group col-6 pe-1');

                        let hcdeZipCodeLabel = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(4) > label');
                        hcdeZipCodeLabel.innerText = "";

                        let hcdeZipCodeInput = document.getElementById('hcdeZipCode');
                        hcdeZipCodeInput.setAttribute('class', 'hcdeComponentTextBox form-control');
                        hcdeZipCodeInput.setAttribute('placeholder', ' ');
                        hcdeZipCodeInput.setAttribute('maxlength', '5');
                        hcdeZipCodeInput.setAttribute('oninput', 'onChangeZipCode()');
                        hcdeZipCodeInput.addEventListener("keypress", e => {
                            if (!digitsOnlyRegExp.test(e.key)) {
                                e.preventDefault();
                            }
                        });

                        let newZipCodeLabel = document.createElement('label');
                        newZipCodeLabel.setAttribute('class', 'hcdeComponentLabel d-inline-block text-start text-truncate form-label col-12');
                        newZipCodeLabel.setAttribute('id', 'hcdeZipCodeLabel');
                        newZipCodeLabel.innerText = "Zip Code";
                        hcdeZipCodeCon.appendChild(newZipCodeLabel);

                        let hcdeButtonCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentButtonContainer');
                        hcdeButtonCon.setAttribute('id', 'hcdeSaveButtonContainer');

                        let hcdeSaveButton = document.getElementById('hcdeComponentSubmitButton');
                        hcdeSaveButton.setAttribute('value', 'Save');

                        // eslint-disable-next-line no-undef
                        checkCardInfo();
                    });
                }
                setTimeout(() => (
                    this.cardOptionTitle = true
                ), 135);  
            });
        },
        payPalOption() {
            this.hcdeReady = false;
            this.hcdeReadyWallet = false;  
            this.hcdeReadyPayPal = true;
            const paypalscripts = [
                this.hcdePaypalComponent				
            ];
            paypalscripts.forEach(script=>{
                let scriptFound = document.head.querySelector(`[src="${ script }"`) ? true : false;
                if (scriptFound) {
                    this.removeJSfile(script);
                }
                let tag = document.head.querySelector(`[src="${ script }"`);
                if (!tag) {
                    tag = document.createElement("script");
                    tag.setAttribute("src", script);
                    tag.setAttribute("type", 'text/javascript');
                    tag.setAttribute("integrity", constants.hcdePaypalComponentHash);
                    tag.setAttribute("crossorigin",'anonymous');
                    document.head.appendChild(tag);
                    tag.addEventListener('load', function () {
                        // eslint-disable-next-line no-undef
                        this.hcdeTagString = new HCDEPaypalServiceComponent();

                        let paymentMethodSelection = document.querySelector('#paymentMethodSelection');
                        let paypalCc = document.createElement('div');
                        paypalCc.setAttribute('id', 'card-img-id');
                        paymentMethodSelection.appendChild(paypalCc);

                        let paypalImg = document.getElementById('paypal-img-id');
                        paypalImg.setAttribute('src', require('../../images/paypal.png'));
                        paypalImg.setAttribute('id', 'paypal-img');
                        paypalImg.setAttribute('onload', 'javascript:switchPaymentMethod("paypal-payment-div")');
                        paypalImg.setAttribute('style', 'cursor: pointer; pointer-events: none; margin-bottom: 25px;');

                        let submitPaypal = document.getElementById('submitPaypal');
                        submitPaypal.setAttribute('style', 'margin-bottom: 25px;');
                        submitPaypal.setAttribute('class', 'hcdeComponentSubmitButton');

                        let paypalPp = document.createElement('div');
                        paypalPp.setAttribute('id', 'paypal-img-id');
                        paymentMethodSelection.appendChild(paypalPp);

                        let element = document.querySelector('.btn-outline-secondary');
                        element.setAttribute('style', 'display: none;');

                        let span = document.querySelector('span');
                        span.setAttribute('style', 'display: none;');

                        let paypalTemsAndCon = document.getElementById('paypalTemsAndCon');
                        paypalTemsAndCon.setAttribute('style', 'position: inherit; height: auto !important; width: auto !important;');

                        let billingAndCancellationTerms = document.getElementById('BillingAndCancellationTermsId');
                        // eslint-disable-next-line no-undef
                        billingAndCancellationTerms.addEventListener('click', openTermsModal);

                        let closeButton = document.getElementById('closeButton');
                        // eslint-disable-next-line no-undef
                        closeButton.addEventListener('click', closeTermsModal);
                    });
                }
                setTimeout(() => (
                    this.payPalOptionTitle = true
                ), 135);
            });
        },
        async createBankPopupModal(token) {
          this.walletInstrumentId = token;
          let bankAccountNum = document.getElementById('bankAccountNumber');
          this.enteredAccountNumber = bankAccountNum.value;
          let bankRoutingNum = document.getElementById('bankRoutingNumber');
          this.enteredRoutingNumber = bankRoutingNum.value;
          let bankAccType = document.getElementById('bankAccountType');
          this.enteredBankType = bankAccType.value;
          this.popupModal.show();
          this.selectedOption = 'Bank'
        },
        async createCardPopupModal(token) {
          this.myTokenVar = token;
          let CardNumber = document.getElementById('hcdeCardNumber');
          this.enteredCardNumber = CardNumber.value;
          let hcdeCardExpMonth = document.getElementById('hcdeCardExpiryMonth');
          this.hcdeCardExpiryMonth = hcdeCardExpMonth.value;
          let hcdeCardExpYear = document.getElementById('hcdeCardExpiryYear');
          this.hcdeCardExpiryYear = hcdeCardExpYear.value;
          let hcdeZip = document.getElementById('hcdeZipCode');
          this.hcdeZipCode = hcdeZip.value;
          this.popupModal.show();
          this.selectedOption = 'Card'
        },
        async createPaypalPopupModal(token) {
          this.myPaypalVar = token;
          this.popupModal.show();
          this.selectedOption = 'Paypal'
        },
        async updateCardAutopay(editCardToken) {
            let localData = new Map();
            localData.set('showLoading', true);
            this.$store.dispatch(local_setdata, {attributes:localData});
            this.ClosePopup()
            try {
                let params = {
                    customerDetails: {
                        addressDetails: {
                            streetAddress1: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.addressDetails[0].billingAddress.streetAddress1,
                            streetAddress2: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.addressDetails[0].billingAddress.streetAddress2,
                            city: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.addressDetails[0].billingAddress.city,
                            addressType: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.addressDetails[0].billingAddress.city,
                            countryCd: "US",
                            postalCd: this.zipCode,
                            stateProvince: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.addressDetails[0].billingAddress.stateProvince
                        },
                        billingAccountNumber: this.$store.getters[GET_DATA]('accountNumber'),
                        billingApplicationCd: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.billingApplicationCd,
                        businessName: "SMALL BUSINESS",
                        customerFirstName: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.customerFirstName,
                        customerLastName: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.customerLastName,
                        customerType: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.customerType,
                        customerEmail: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.email ? this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.email : this.$store.getters[GET_DATA]('emailAddress'),
                        paperLessBillingInd: "N"
                    },
                    paymentMethodDetails: {
                        walletDetails: {
                            walletReferenceNumber: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].autopayEnrollmentInfo.autopayWalletId
                        },
                        cardDetails: {
                            cardToken: editCardToken
                        }
                    },
                    enrollmentType: "ModifyEnroll",
                    userId: "DSSP",
                    paymentMethodCd: 'CARD',
                    currencyCd: "USD"
                }
                const res = await axios.post(constants.AutopayEnrollAndDeEnroll, params);
                this.transactionStatuscard = res.data.transactionStatus;
                if (this.transactionStatuscard == 'S') {
                    let attributesData = new Map();
                    attributesData.set("modifyenrollment", 'AutoPayModifyEnrollment');
                    this.$store.dispatch(SET_DATA, { attributes: attributesData });
                    this.manageModal.hide();
                    localData.set('showLoading', false);
                    this.$store.dispatch(local_setdata, {attributes:localData});
                    this.$router.push({
                        name: "SuccessAP",
                        params: {},
                    });
                } else {
                    this.manageModal.hide();
                    localData.set('showLoading', false);
                    this.$store.dispatch(local_setdata, {attributes:localData});
                    this.$router.push({
                        name: "FailureAP",
                        params: {},
                    });
                }
            } catch (error) {
                console.log(error);
                this.manageModal.hide();
                localData.set('showLoading', false);
                this.$store.dispatch(local_setdata, {attributes:localData});
                this.$router.push({
                    name: "FailureAP",
                    params: {},
                });
            }
        },
        async updateBankAutopay(editBankToken) {
            let localData = new Map();
            localData.set('showLoading', true);
            this.$store.dispatch(local_setdata, {attributes:localData});
            this.ClosePopup();
            try {
                let params = {
                    customerDetails: {
                        addressDetails: {
                            streetAddress1: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.addressDetails[0].billingAddress.streetAddress1,
                            streetAddress2: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.addressDetails[0].billingAddress.streetAddress2,
                            city: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.addressDetails[0].billingAddress.city,
                            addressType: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.addressDetails[0].billingAddress.city,
                            countryCd: "US",
                            postalCd: this.zipCode,
                            stateProvince: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.addressDetails[0].billingAddress.stateProvince
                        },
                        billingAccountNumber: this.$store.getters[GET_DATA]('accountNumber'),
                        billingApplicationCd: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.billingApplicationCd,
                        businessName: "SMALL BUSINESS",
                        customerFirstName: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.customerFirstName,
                        customerLastName: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.customerLastName,
                        customerType: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.customerType,
                        customerEmail: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.email ? this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.email : this.$store.getters[GET_DATA]('emailAddress'),
                        paperLessBillingInd: "N"
                    },
                    paymentMethodDetails: {
                        walletDetails: {
                            walletReferenceNumber: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].autopayEnrollmentInfo.autopayWalletId
                        },
                        bankAccountDetails: {
                            achToken: editBankToken
                        }
                    },
                    enrollmentType: "ModifyEnroll",
                    userId: "DSSP",
                    paymentMethodCd: 'ACH',
                    currencyCd: "USD"
                }
                const res = await axios.post(constants.AutopayEnrollAndDeEnroll, params);
                this.transactionStatusbank = res.data.transactionStatus;
                if (this.transactionStatusbank == 'S') {
                    let attributesData = new Map();
                    attributesData.set("modifyenrollment", 'AutoPayModifyEnrollment');
                    this.$store.dispatch(SET_DATA, { attributes: attributesData });
                    localData.set('showLoading', false);
                    this.$store.dispatch(local_setdata, {attributes:localData});
                    this.manageModal.hide();
                    this.$router.push({
                        name: "SuccessAP",
                        params: {},
                    });
                } else {
                    this.manageModal.hide();
                    localData.set('showLoading', false);
                    this.$store.dispatch(local_setdata, {attributes:localData});
                    this.$router.push({
                        name: "FailureAP",
                        params: {},
                    });
                }
            } catch (error) {
                console.log(error);
                this.manageModal.hide();
                localData.set('showLoading', false);
                this.$store.dispatch(local_setdata, {attributes:localData});
                this.$router.push({
                    name: "FailureAP",
                    params: {},
                });
            }
        },
        async updatePaypalAutopay(editPaypalToken) {
            let localData = new Map();
            localData.set('showLoading', true);
            this.$store.dispatch(local_setdata, {attributes:localData});
            this.ClosePopup()
            try {
                let params = {
                    customerDetails: {
                        addressDetails: {
                            streetAddress1: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.addressDetails[0].billingAddress.streetAddress1,
                            streetAddress2: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.addressDetails[0].billingAddress.streetAddress2,
                            city: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.addressDetails[0].billingAddress.city,
                            addressType: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.addressDetails[0].billingAddress.city,
                            countryCd: "US",
                            postalCd: this.zipCode,
                            stateProvince: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.addressDetails[0].billingAddress.stateProvince
                        },
                        billingAccountNumber: this.$store.getters[GET_DATA]('accountNumber'),
                        billingApplicationCd: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.billingApplicationCd,
                        businessName: "SMALL BUSINESS",
                        customerFirstName: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.customerFirstName,
                        customerLastName: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.customerLastName,
                        customerType: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.customerType,
                        customerEmail: this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.email ? this.customerDetails.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.email : this.$store.getters[GET_DATA]('emailAddress'),
                        paperLessBillingInd: "N"
                    },
                    paymentMethodDetails: {
                        walletDetails: {
                            walletReferenceNumber: editPaypalToken,
                            instrumentId: editPaypalToken
                        }
                    },
                    enrollmentType: "ModifyEnroll",
                    userId: "DSSP",
                    paymentMethodCd: 'PAYPAL',
                    currencyCd: "USD"
                }
                const res = await axios.post(constants.AutopayEnrollAndDeEnroll, params);
                this.transactionStatuscard = res.data.transactionStatus;
                if (this.transactionStatuscard == 'S') {
                    let attributesData = new Map();
                    attributesData.set("modifyenrollment", 'AutoPayModifyEnrollment');
                    this.$store.dispatch(SET_DATA, { attributes: attributesData });
                    this.manageModal.hide();
                    localData.set('showLoading', false);
                    this.$store.dispatch(local_setdata, {attributes:localData});
                    this.$router.push({
                        name: "SuccessAP",
                        params: {},
                    });
                } else {
                    this.manageModal.hide();
                    localData.set('showLoading', false);
                    this.$store.dispatch(local_setdata, {attributes:localData});
                    this.$router.push({
                        name: "FailureAP",
                        params: {},
                    });
                }
            } catch (error) {
                console.log(error);
                this.manageModal.hide();
                localData.set('showLoading', false);
                this.$store.dispatch(local_setdata, {attributes:localData});
                this.$router.push({
                    name: "FailureAP",
                    params: {},
                });
            }
        },
        
    }
}
</script>
<style>
.col-sm-08 {
  flex: 0 0 auto;
  width: 49.666667% !important;
}
.large {
  text-align: left;
  --bs-modal-width: 760px;
}
.content-size {
  padding: 1rem;
}
.align-center {
  padding: 1rem;
  display: flex;
  justify-content: center;
}
.flex-component {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  font-size: 16px;
  text-align: left;
  gap: .5rem;
}
.flex-component-button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.content-spacing {
  padding: 1.75rem;
}
.alert-content {
  padding-top: 0.75rem;
}
.flex-entry {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-size: 16px;
  text-align: end;
  margin-left: 1rem;
  gap: 0.75rem;
}
.flex-entry-button {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-size: 16px;
  text-align: end;
  margin-left: 0rem;
  gap: 0.75rem;
}
.flex-label {
  width: 10rem;
}
.flex-span {
  display: flex;
  flex-direction: row;
}
.Submit-Button{
  background-color: #0047bb;
  border-radius: 4px;
  font-weight: 400;
  height: 36px;
  width: 90px;
  padding: 0;
  text-transform: none;
  margin-left: 5px;
  display: inline-block;
  text-decoration: none;
  color: #fff;
  border: none;
}
.cancel-button {
  border: none;
  color: #0047bb;
  text-decoration: underline;
  background: none;
  padding: 0rem;
}
#confirmationModal .modal-body {
  padding-top: 0 !important;
  text-align: left;
  padding-left: 0.5rem;
}
#confirmationModal .no-border {
  text-align: left;
}
#prepaidUpdateBtn, #prepaidUpdateBtnCard {
    width: 190px;
    background-color: white;
    color: #0047bb;
    border: 1px solid #0047bb;
    box-shadow: none;
    font-family: 'Maison Neue Book', 'Maison Neue Medium', 'Maison Neue Bold', 'Maison Neue Extra Bold', sans-serif;
    font-size: 14px;
    border-radius: 4px;
    font-weight: 400;
    height: 36px;
    padding: 0 15px;
    text-transform: none;
    display: inline-block;
}

#infoImg {
    height: 24px;
    width: fit-content;
    padding: 0;
    margin-left: auto;
}

#infoMsg {
    font-style: italic;
    width: fit-content;
    padding: 0;
    margin-top: 0.25rem;
    margin-left: 0.50rem;
    margin-right: auto;
}

.bill-detail-font {
    font-size: 12px;
    font-weight: 700;
}

.text-decor {
    color: #14161a;
    font-weight: 500;
    margin-top: 1rem;
}

.bill-details-box {
    background-color: #ffffff;
    height: auto;
    box-shadow: 0px 2px 4px #00000033;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 10px;
    color: #515255;
}

.bill-header {
    margin: 1rem 0 1rem 0;
    display: flex;
}

.content-margin {
    margin: 2rem 0 2rem 0;
}
.content-margin p {
    line-height: 24px;
}
.learn-more {
    color: #0047bb;
    text-decoration: none !important;
}

.bill-icon {
    height: 15px;
    width: 15px;
    margin: 0 0.5rem 0 0.5rem;
}

@media(min-width: 992px) {
    .autopay-box {
        width: 70%;
        height: 100%;
        border-radius: 5px;
    }
}

.text-decor-left {
    color: #14161a;
    font-weight: 500;
    margin-top: 1rem;
    font-size: 24px;
    text-align: left;
}

.upperCase {
    text-transform: uppercase;
}

.noData-content-margin {
    margin-left: auto;
}

@media(min-width: 576px) {
    .noData-content-margin {
        margin: 2rem 0 2rem auto;
    }
}
.bill-details-box h2 {
    display: inline-block;
    font-size: 14px;
}
.visa-img, .mastercard-img, .discover-img {
    height: 30px;
    width: 32px;
    margin-left: 10px;
}
.bank-img-section {
    text-align: left;
}
.align-left {
    text-align: left;
}
#autopayRemoveBtn {
    background-color: white;
    border: none;
    color: #0047bb;
    width: auto;
    font-size: 14px;
    display: inline-block;
    padding: 0 15px;
}
.autopay-block {
    display: block;
}
.bank-card-section {
    width: 94%;
    margin: 0 auto;
    padding-top: 50px;
    padding-bottom:60px;
}
@media (min-width: 767px) {
   .bank-card-section {
        width: 70%;
    } 
}
.edit-bank-icon-circle{
    position: relative;
    width: 48px;
    height: 48px;
    margin: 0 auto;
}
.edit-bank-icon-circle img{
    width: 100%;
}
.edit-card-circle{
    width: 48px;
    height: 48px;
    margin: 0 auto;
} 
.edit-card-circle img{
    width: 100%;
}
.edit-paypal-circle{
    width: 84px;
    height: 48px;
    margin: 0 auto;
}
.edit-paypal-circle img{
    width: 100%;
}
.edit-bank {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #00000029;
    border: 1px solid #EEEEEE33;
    border-radius: 8px;
    opacity: 1;
    width: 48%;
    margin-right: 2%;
    float: left;
    padding: 15px 0;
    cursor: pointer;
}
.update-card {
    width: 48%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #00000029;
    border: 1px solid #EEEEEE33;
    border-radius: 8px;
    opacity: 1;
    align-items: right;
    float: left;
    margin-left: 2%;
    padding: 15px 0;
    cursor: pointer;
}
.update-paypal {
    width: 48%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #00000029;
    border: 1px solid #EEEEEE33;
    border-radius: 8px;
    opacity: 1;
    align-items: right;
    float: left;
    margin-left: 25%;
    margin-top: 4%;
    padding: 15px 0;
    cursor: pointer;
}
.update-bank-card .modal-body {
    float:left;
}
.hcde-comp {
    padding: 0 10px;
}
@media(max-width:767px) {
  .flex-component-button {
    gap: 3rem;
  }
  .large{
    --bs-modal-width: 600px !important;
  }
  .p {
    font-size: 14px;
  }
  .flex-entry{
    margin-left: -2rem;
    gap: 0.25rem;
  }
  .align-center {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
  .flex-span {
    word-break: break-all;
  }
}
</style>