<template>
  <div>
    <PAOptions/>
  </div>
  <link rel="stylesheet" type="text/css" :href="this.hcdeTokenTheme" :integrity="this.hcdeTokenThemeHash" crossorigin="anonymous"/>
  <link rel="stylesheet" type="text/css" :href="this.hcdewalletTheme" :integrity="this.hcdewalletThemeHash" crossorigin="anonymous" />
</template>

<script>
  import PAOptions from '../components/paymentArrngmts/PAOptions.vue'
  import links from '../js/links';
  import constants from '../js/constants';
  export default {
    name: 'PaymentArrngmtsIdentify',
    components: {
      PAOptions
    },
    data() {
      return {
        isBrimOn: false,
        hcdeTokenTheme: null,
        hcdewalletTheme: null,
        hcdeTokenThemeHash: null,
        hcdewalletThemeHash: null
      }
    },
    mounted() {
      this.isBrimOn = process.env.VUE_APP_BRIM;
      if(this.isBrimOn == 'true'){
        this.hcdeTokenTheme = links.hcdeTokenTheme;
        this.hcdewalletTheme = links.hcdewalletTheme;
        this.hcdeTokenThemeHash = constants.hcdeTokenThemeHash;
        this.hcdewalletThemeHash = constants.hcdewalletThemeHash;
      }
    }
  }
</script>

<style scoped>
</style>