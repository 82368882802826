<template>
    <div class="container d-flex justify-content-center mw-box">
      <manage-wallet/>
    </div>
    <link rel="stylesheet" type="text/css" :href="this.hcdeTokenTheme" :integrity="this.hcdeTokenThemeHash" crossorigin="anonymous"/>
    <link rel="stylesheet" type="text/css" :href="this.hcdewalletTheme" :integrity="this.hcdewalletThemeHash" crossorigin="anonymous" />
</template>

<script>
  import ManageWallet from '../components/wallet/manageWallet.vue';
  import links from '../js/links';
  import constants from '../js/constants';
  export default {
    name: 'MgeWallet',
    components: {
      ManageWallet
    },
    data() {
      return {
        isBrimOn: false,
        hcdeTokenTheme: null,
        hcdewalletTheme: null,
        hcdeTokenThemeHash: null,
        hcdewalletThemeHash: null
      }
    },
    mounted() {
      this.isBrimOn = process.env.VUE_APP_BRIM;
      if(this.isBrimOn == 'true'){
        this.hcdeTokenTheme = links.hcdeTokenTheme;
        this.hcdewalletTheme = links.hcdewalletTheme;
        this.hcdeTokenThemeHash = constants.hcdeTokenThemeHash;
        this.hcdewalletThemeHash = constants.hcdewalletThemeHash;
      }
    }
  }
</script>

<style scoped>
  @media (min-width:992px) {
    .mw-box {
    width: 65%;
    }  
  }
</style>